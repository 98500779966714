<template>
  <div :class="`theme-${theme}`" v-if="GPSs.length">
    <h2>{{ title }}</h2>
    <div class="i-container">
      <div v-for="GPS in GPSs" :key="GPS.imei" :class="checkStatus(GPS.status)">
        <b-checkbox v-model="GPS.onMap" @input="onInput">
          <span @click.prevent="onTextClick(GPS)">
            <b-icon icon="map-marker" />
          </span>
          {{ GPS[model] }}
          - <small>{{ GPS.status }}</small>
        </b-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onTextClick(GPS) {
      GPS.onMap = true;
      if (GPS.onMap) this.$emit('input', this.GPSs, GPS);
      else this.$emit('input', this.GPSs);
    },
    checkStatus(status) {
      switch (status.toLowerCase()) {
        case 'not active':
        case 'offline':
          return 'is-disabled';
      }
    },
    onInput() {
      this.$emit('input', this.GPSs);
    }
  },
  props: {
    theme: { type: String, default: '' },
    GPSs: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    model: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
h2
  font-size: 16px
  font-weight: bold
  margin: 10px 0 20px
.i-container
  max-height: 190px
  overflow: auto
  padding-bottom: 10px
  border-bottom: 1px solid $main-separators

$themes: 'warning' $warning,'primary' $primary
@each $name, $theme in $themes
  .theme-#{$name}
    ::v-deep
      .checkbox
        width: calc(100% - 10px)
        &:hover
          color: $theme!important
          .check
            border-color: $theme!important
      input
        border-color: $theme!important
        &:focus + .check
          // box-shadow: none!important
          box-shadow: 0 0 0.5em transparentize($theme,.5)!important
        &:checked + .check
          border-color: $theme!important
          background-color: $theme!important
    .icon
      color: $theme
</style>
