<template>
  <div id="map-address-picker" :class="`${isHidden ? 'hidden' : ''}`">
    <div class="mTitle flex f-jsb f-ai f-jc">
      <h2>{{ $t('address.picker.title') }}</h2>
      <Link class="close" icon="close" @click="close" />
    </div>
    <InputSearch
      @input="
        (address) => {
          geocoding({ address });
        }
      "
      :timeout="700"
      :loading="loading.search"
      v-model="search"
    />
    <MessageBadge :message="$t('address.picker.info')" type="is-info" />
    <MessageBadge
      v-if="!loading.search && !pickerData.isValid"
      :message="$t('address.invalid')"
      type="warning"
    />
    <div class="item">
      <strong>{{ $tc('address.address') }}</strong>
      {{ pickerData.address.number || '' }}
      {{ pickerData.address.street || '--' }}
    </div>
    <div class="item">
      <strong>{{ $tc('address.city') }}</strong>
      {{ pickerData.address.city || '--' }}
    </div>
    <div class="item">
      <strong>{{ $tc('address.state') }}</strong>
      {{ pickerData.address.state || '--' }}
    </div>
    <div class="item">
      <strong>{{ $tc('address.zp') }}</strong>
      {{ pickerData.address.zipcode || '--' }}
    </div>
    <b-button
      type="is-primary"
      @click="onSelect"
      :label="$t('address.picker.btn')"
      :disabled="loading.search || !pickerData.isValid"
      :loading="loading.save"
    >
    </b-button>
  </div>
</template>

<script>
import { InputSearch, MessageBadge, Link } from '@/components';
import { normalizeGeocoderAddressComponents } from '@/utils/MapFunctions.js';

export default {
  components: {
    InputSearch,
    MessageBadge,
    Link
  },
  mounted() {
    if (window.google) this.init();
  },
  destroyed() {
    this.$emit('input', null);
  },
  data() {
    return {
      geocoder: null,
      search: '',
      isHidden: false,
      loading: { search: false, save: false },
      pickerData: {
        address: {},
        isValid: false,
        position: { lat: null, lng: null }
      }
    };
  },
  methods: {
    init() {
      this.geocoder = new window.google.maps.Geocoder();
      if (this.value) {
        this.pickerData = this.value;
        this.doAutoSearch();
      } else this.resetData();
    },
    focusToPosition(position, doPanTo) {
      if (!position) return;
      if (doPanTo) {
        this.map.panTo(position);
        this.map.setZoom(18);
      }
    },
    doAutoSearch() {
      if (this.pickerData.time) return;
      const { address = {}, position } = this.pickerData;
      if (!address.state && position) {
        this.geocoding({ location: position });
      } else if (position) {
        this.focusToPosition(position, true);
      } else {
        const { city, number, state, street, zipcode } = address;
        const props = [];
        if (street) props.push(`${(number && `${number} `) || ''}${street}`);
        if (city) props.push(city);
        if (state) props.push(state);
        if (zipcode) props.push(zipcode);
        this.search = props.join(', ');
        this.geocoding({ address: this.search });
      }
    },
    onSelect() {
      const { address, position } = this.pickerData;
      const data = { address, position };
      this.$store.commit('map/response', { action: 'position', data });
      this.loading.save = true;
      this.close();
    },
    close() {
      this.isHidden = true;
      this.$store.commit('map/update', { action: 'restore' });
      setTimeout(() => {
        this.$emit('close');
      }, 500);
    },
    resetData({ time }) {
      const mapCenter = this.map.getCenter();
      this.pickerData = {
        address: {},
        isValid: false,
        time,
        position: {
          lat: mapCenter.lat(),
          lng: mapCenter.lng()
        }
      };
    },
    // APPROXIMATE
    filterPlacesByType({ results, aTypes = ['street_address', 'premise', 'subpremise'] }) {
      const places = results.filter(({ types }) => {
        return types.some((t) => aTypes.filter((at) => at === t)[0]);
      });
      return places.sort((a, b) => {
        if (a.address_components.length > b.address_components.length) return 1;
        if (a.address_components.length < b.address_components.length) return -1;
        return 0;
      })[0];
    },
    decodeGAddress(data, location) {
      if (!data) throw Error;
      const { formatted_address, address_components, geometry } = data;
      const position = { lat: geometry.location.lat(), lng: geometry.location.lng() };
      const result = {
        isValid: true,
        address: {
          ...normalizeGeocoderAddressComponents(address_components),
          formatted_address
        },
        position: location || position
      };
      return result;
    },
    processNewData(newData) {
      const time = new Date().getTime();
      if (newData) {
        this.pickerData = {
          ...this.pickerData,
          ...newData,
          time
        };
      } else {
        this.resetData({ time });
      }
    },
    async geocoding({ address, location }) {
      let payload = (address && { address }) || (location && { location });
      if (!payload) return;
      let resultData = null;
      this.loading.search = true;
      try {
        const { results } = await this.geocoder.geocode(payload);
        const result = this.filterPlacesByType({ results });
        resultData = this.decodeGAddress(result, location);
        this.focusToPosition(resultData.position, !location);
      } catch (error) {
        console.error(error);
      }
      this.loading.search = false;
      this.processNewData(resultData);
    },
    emit() {
      this.$emit('input', this.pickerData);
    }
  },
  watch: {
    map() {
      if (this.map) this.init();
    },
    pickerData() {
      this.emit();
    },
    // value(value, oldValue) {
    //   const { position: vPosition } = value;
    //   const { position: oPosition } = oldValue;
    //   console.log('::', vPosition.lat);
    //   console.log('::', oPosition.lat);
    //   console.log('::', vPosition === oPosition);
    //   this.pickerData = value;
    //   this.doAutoSearch();
    // }
    value: {
      deep: true,
      handler(value) {
        if (value.loading || value.time) return;
        if (!value.address) {
          value.address = {};
          value.isValid = false;
        }
        this.pickerData = value;
        this.doAutoSearch();
      }
    }
  },
  props: {
    map: { required: true },
    value: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#map-address-picker
  padding: 24px
  background: white
  position: absolute
  left: 0
  z-index: 100
  width: 450px
  background: $main-background
  border: 1px solid $gray-100
  &.hidden
    transition: all .3s ease-in-out
    opacity: 0
  .mTitle
    margin-bottom: 20px
    h2
      font-weight: 700
      font-size: $f-xl
  .item,
  .control,
  .message-badge
    margin-bottom: 10px
  strong
    display: block
    color: $gray-700
  .button
    width: 100%
</style>
