<template>
  <div id="error-404" :class="isDarkMode ? 'dark' : ''">
    <div class="header flex">
      <img v-if="isDarkMode" class="logo" src="@/assets/img/logo-dark.svg" alt="CFS logo" />
      <img v-else class="logo" src="@/assets/img/logo-light.svg" alt="CFS logo" />
      <h1>Error: 404</h1>
    </div>
    <div class="content-404 flex f-ai f-jc">
      <div class="card-container flex f-ai f-jc">
        <h1 class="mb-4">{{ $t('error404.info') }}</h1>
        <img src="@/assets/img/404.svg" alt="404 error" /> <br />
        <Link class="mt-2">
          <b-button type="is-primary" :label="$t('error404.goBack')" @click="goBack" />
        </Link>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@/components';
export default {
  components: {
    Link
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.DARK;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="sass" scoped>
#error-404
  background-color: $main-background
  height: 100vh
  h1
    font-weight: bold
    font-size: $f-xxl
    margin-left: 24px
  .header
    background-color: #FFF
    padding: 32px
    height: 96px
    img
      height: 45px
      width: 82
  .content-404
    height: calc(100% - 96px)
    .card-container
      background-color: #FFF
      border-radius: $br-lg
      flex-direction: column
      width: 600px
      height: 615px
      h1
        font-size: 30px
</style>
<style lang="sass" scoped>
.dark
  background-color: $dark
  color: #FFF
  .header
    background-color: $dark-200 !important
  .content-404
    .card-container
      background-color: $dark-200 !important
</style>
