<template>
  <div v-if="deliveries.length">
    <div
      v-for="delivery in deliveries"
      :key="`${delivery.id}-${delivery.delivery_event_status_id}-${delivery.delivery_event_type_id}`"
    >
      <DeliveryStatusCard
        v-once
        :delivery="delivery"
        @shareRx="(v) => emit('shareRx', v)"
        @focusToDelivery="() => $emit('focusToDelivery', delivery)"
        @statusTagClick="(v) => emit('statusTagClick', v)"
        @orderNumberClick="(v) => emit('orderNumberClick', v)"
        useStatusIcon
      />
    </div>
  </div>
  <Empty v-else />
</template>

<script>
import DeliveryStatusCard from './DeliveryStatusCard.vue';
import { Empty } from '@/components';
export default {
  components: {
    DeliveryStatusCard,
    Empty
  },
  data() {
    return {
      deliveries: this.value
    };
  },
  methods: {
    emit(name, value) {
      this.$emit(name, value);
    }
  },
  watch: {
    value(value) {
      this.deliveries = value;
    }
  },
  props: {
    value: { type: Array, default: () => [] }
  }
};
</script>
