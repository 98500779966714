<template>
  <div id="dispatchMap" :class="isDriverPanelOpen ? 'is-driver-panel-open' : ''">
    <ViewHistory />
    <DeliveryStatus
      :manifestId="manifestId"
      :driver="driver"
      @update="onDeliveriesUpdate"
      @focusToDelivery="onFocusToDelivery"
      :scrollTo="scrollDeliveriesTo"
    />
    <DriverStatus
      @update="onDriversUpdate"
      :isOpenPanel.sync="isDriverPanelOpen"
      @showOnMap="onFocusToDriver"
    />
    <Dashboard @showOnMap="setDashboardMarkers" />
    <NMap
      ref="NMap"
      id="map"
      type="gMap"
      :center="oMap.center"
      :zoom="oMap.zoom"
      :darkMode="this.darkMode"
    >
      <UnassignedDeliveriesMarker
        icon="circle-medium"
        color="#5265F7"
        :data="UnassignedDeliveriesMarker"
        @removed="
          () => {
            UnassignedDeliveriesMarker = null;
          }
        "
      />
      <NCluster id="DeliveriesByManifest">
        <DeliveryMarker
          v-for="marker in deliveries.markers"
          :key="`delivery-marker-${marker.id}`"
          @click="() => (deliveries.infowindowId = marker.id)"
          :delivery="marker"
          :isImportant="deliveries.infowindowId == marker.id"
        />
      </NCluster>
      <NInfowindow
        v-if="infowindowDelivery"
        :position="infowindowDelivery.position"
        @close="() => (deliveries.infowindowId = null)"
      >
        <table style="max-width: 300px">
          <tr v-if="infowindowDelivery.secuence - $store.getters['deliveries/OFFSET'] > 0">
            <td class="p-1">
              <strong>{{ $t('global.secuence') }}</strong>
            </td>
            <td class="p-1">
              {{ infowindowDelivery.secuence - $store.getters['deliveries/OFFSET'] }}
            </td>
          </tr>
          <tr v-if="infowindowDelivery.participant_full_name">
            <td class="p-1">
              <strong>{{ $t('global.name') }}</strong>
            </td>
            <td class="p-1">{{ infowindowDelivery.participant_full_name }}</td>
          </tr>
          <tr v-if="infowindowDelivery.destination_full_address">
            <td class="p-1">
              <strong>{{ $tc('address.address') }}</strong>
            </td>
            <td class="p-1">
              <strong> {{ infowindowDelivery.destination_full_address }} </strong>
            </td>
          </tr>
          <tr v-if="infowindowDelivery.tracking_number">
            <td class="p-1" style="width: 100px">
              <strong>{{ $t('global.orderNumber') }}</strong>
            </td>
            <td class="p-1">
              <CopyTextToClipBoard
                class="copy-clip"
                positionLeft
                :copyToText="infowindowDelivery.tracking_number"
                :text="`#${infowindowDelivery.tracking_number}`"
              />
            </td>
          </tr>
          <tr v-if="infowindowDelivery.tracking_number && driver">
            <td class="p-1">
              <strong>{{ $t('global.driver') }}</strong>
            </td>
            <td class="p-1">{{ driver.employee_name }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <Link @click="() => $store.commit('dispatch/deliveryHistory', infowindowDelivery)">
                <strong>{{ $t('global.viewHistory') }}</strong>
              </Link>
            </td>
          </tr>
        </table>
      </NInfowindow>
      <NMarker
        v-for="driver in filteredDrivers"
        :zIndex="99"
        :key="`driver-${driver.id}`"
        :position="driver.position"
        icon="car"
        :iconColor="driver.color"
        @click="
          () => {
            drivers.infowindowId = driver.id;
          }
        "
      />
      <NInfowindow
        v-if="infowindowDriver"
        :position="infowindowDriver.position"
        @close="() => (drivers.infowindowId = null)"
      >
        <table class="table">
          <tr>
            <td>
              <strong>IMEI: </strong>
            </td>
            <td>
              {{ infowindowDriver.imei }}
            </td>
          </tr>
          <tr>
            <td>
              <strong> {{ $t('global.driver') }}: </strong>
            </td>
            <td>
              {{ infowindowDriver.employee_name }}
            </td>
          </tr>
          <tr>
            <td>
              <strong> {{ $t('global.speed') }}: </strong>
            </td>
            <td>
              {{ infowindowDriver.speed }}
            </td>
          </tr>
        </table>
      </NInfowindow>
      <!-- Dashboard -->
      <NCluster id="Dashboard" v-if="dashboard.markers.length">
        <NMarker
          v-for="marker in dashboard.markers"
          :key="`dashboard-marker-${marker.id}`"
          :position="marker.position"
          :zIndex="3"
          :icon="marker.icon"
          :iconColor="marker.color"
          :iconSecondaryColor="marker.deliveryTimeRestrictionColor"
          @click="
            () => {
              dashboard.infowindowId = marker.id;
            }
          "
        />
      </NCluster>
      <NInfowindow
        v-if="infowindowDashboard"
        :position="infowindowDashboard.position"
        @close="() => (dashboard.infowindowId = null)"
      >
        <table style="max-width: 450px" v-if="infowindowDashboard.typeMarker === 'cdp'">
          <tr>
            <th colspan="2">{{ $t('cdp.info') }}</th>
          </tr>
          <tr>
            <td>
              <strong> {{ $t('global.name') }}: </strong>
            </td>
            <td>
              {{ infowindowDashboard.name }}
            </td>
          </tr>
          <tr>
            <td>
              <strong> {{ $t('contact.address') }}: </strong>
            </td>
            <td>
              {{ infowindowDashboard.full_address }}
            </td>
          </tr>
          <tr>
            <td>
              <strong> {{ $t('contact.phone') }}: </strong>
            </td>
            <td>
              <PhoneViewer :phone="infowindowDashboard.phone" />
            </td>
          </tr>
        </table>
        <table style="max-width: 450px" v-else>
          <tr>
            <td class="p-1">
              <strong> {{ $t('global.name') }}: </strong>
            </td>
            <td class="p-1">
              {{ infowindowDashboard.participant_full_name }}
            </td>
          </tr>
          <tr>
            <td class="p-1">
              <strong> {{ $t('contact.address') }}: </strong>
            </td>
            <td class="p-1">
              <strong>
                {{
                  infowindowDashboard.destination_full_address ||
                  infowindowDashboard.participant_full_address
                }}
              </strong>
            </td>
          </tr>
          <tr>
            <td class="p-1" style="width: 100px">
              <strong> {{ $t('global.orderNumber') }}: </strong>
            </td>
            <td class="p-1">
              <CopyTextToClipBoard
                class="copy-clip"
                positionLeft
                :copyToText="infowindowDashboard.tracking_number"
                :text="`#${infowindowDashboard.tracking_number}`"
              />
            </td>
          </tr>
          <tr v-if="infowindowDashboard.cdp_full_address">
            <td class="p-1">
              <strong>CDP: </strong>
            </td>
            <td class="p-1">
              {{ infowindowDashboard.cdp_name }}
              <br />
              <small>{{ infowindowDashboard.cdp_full_address }}</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="p-1">
              <ConstraintDatesViewer
                v-if="
                  infowindowDashboard.delivery_start_time || infowindowDashboard.delivery_end_time
                "
                isTag
                :startTime="infowindowDashboard.delivery_start_time"
                :endTime="infowindowDashboard.delivery_end_time"
                :offset="infowindowDashboard.offset_tz"
              />
            </td>
          </tr>
        </table>
      </NInfowindow>
      <!-- End Dashboard -->
      <NPolyline
        v-if="manifestRoute"
        :encodedPath="manifestRoute"
        :color="polylineColor"
        showDirection
      />
    </NMap>
  </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps';

import { find, propEq } from 'ramda';
import { DeliveryMarker, Link, CopyTextToClipBoard } from '@/components';
import {
  Dashboard,
  DeliveryStatus,
  DriverStatus,
  UnassignedDeliveriesMarker
} from '@/components/Dispatch/Map';
import { NCluster, NMap, NMarker, NInfowindow, NPolyline } from '@/components/Map/Plugins';
import ViewHistory from '@/components/Dispatch/Modals/ViewHistory.vue';
import { PhoneViewer } from '../../../components';
import ConstraintDatesViewer from '../../../components/ConstraintDatesViewer.vue';

export default {
  components: {
    CopyTextToClipBoard,
    Dashboard,
    DeliveryMarker,
    DeliveryStatus,
    DriverStatus,
    Link,
    NCluster,
    NInfowindow,
    NMap,
    NMarker,
    NPolyline,
    UnassignedDeliveriesMarker,
    ViewHistory,
    PhoneViewer,
    ConstraintDatesViewer
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      const isManifestUpdate = type === 'map/manifestId';
      const isTimelineManifestUpdate = type === 'map/timelineManifest';
      const isFocusToDelivery = type === 'map/focusedDelivery';
      const isShowMarker = type === 'map/showMarker';

      if (isShowMarker) this.onFocusToMarker(map.aMarker);

      if (isFocusToDelivery && map.focusedDelivery) {
        const isSameManifest = this.manifestId === map.focusedDelivery.delivery_manifest_id;
        if (isSameManifest) this.onFocusToDelivery(map.focusedDelivery);
        else this.manifestId = map.focusedDelivery.delivery_manifest_id;
      }

      if (isTimelineManifestUpdate) {
        map.timelineManifest ? this.onFocusToManifest(map.timelineManifest) : this.resetMap();
      }

      if (isManifestUpdate) this.manifestId = map.manifestId;
    });
  },
  destroyed() {
    this.unsubscribe();
    clearTimeout(this.timeout);
  },
  data() {
    return {
      center: { lat: 36.1859, lng: -117.2418 },
      isDriverPanelOpen: true,
      isFirstUpdate: { deliveries: true, drivers: true },
      manifest: null,
      manifestRoute: null,
      manifestId: null,
      markers: { simple: null },
      oMap: { center: { lat: 36.610423, lng: -119.928424 }, zoom: 8 },
      scrollDeliveriesTo: {},
      UnassignedDeliveriesMarker: null,
      deliveries: { markers: [], infowindowId: null },
      drivers: { markers: [], infowindowId: null },
      dashboard: { markers: [], infowindowId: null }
    };
  },
  computed: {
    dashboardInfoWindowPosition() {
      return { lat: this.infowindow.dashboard.lat, lng: this.infowindow.dashboard.lng };
    },
    vehicleInfoWindowPosition() {
      return { lat: this.infowindow.vehicle.lat, lng: this.infowindow.vehicle.lng };
    },
    google: VueGoogleMaps.gmapApi,
    map() {
      return this.$refs.NMap;
    },
    polylineColor() {
      return this.driver?.color || '#026C75';
    },
    driver() {
      if (!this.manifestId) return;
      return find(propEq('delivery_manifest_id', this.manifestId), this.drivers.markers);
    },
    hasDashboardMarkers() {
      return this.dashboard.markers.length;
    },
    manifestIdByDeliveries() {
      return this.deliveries.markers[0]?.delivery_manifest_id;
    },
    filteredDrivers() {
      try {
        const { driver } = this;
        const googleTestDriverId = 18; // FILTER GOOGLE USER
        const drivers = this.drivers.markers;

        const cDriver = drivers?.find(
          (d) => d.delivery_manifest_id === this.manifestIdByDeliveries
        );

        if (cDriver && cDriver.position) return [cDriver];

        if (driver && !driver.position) return [];
        return driver ? [driver] : drivers.filter((m) => m.position && m.id !== googleTestDriverId);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    infowindowDelivery() {
      return find(propEq('id', this.deliveries.infowindowId), this.deliveries.markers);
    },
    infowindowDriver() {
      return find(propEq('id', this.drivers.infowindowId), this.drivers.markers);
    },
    infowindowDashboard() {
      return find(propEq('id', this.dashboard.infowindowId), this.dashboard.markers);
    }
  },
  methods: {
    formatTime(dateTime) {
      if (!dateTime) return '-';
      const utcDate = this.$moment.utc(dateTime).toDate();
      return this.$moment(utcDate).format('Y-MM-DD hh:mm A');
    },
    onDriversUpdate(data) {
      this.drivers.markers = data;
      if (this.isFirstUpdate.drivers) {
        this.panToDrivers();
        this.isFirstUpdate.drivers = false;
      }
      //! to Validate
      // if (this.infowindowDriver) this.onFocusToDriver(this.driver);
    },
    autoZoomDeliveries(events) {
      const path = [];
      const inProgressEvent = events.find(
        (event) => [2, 3].indexOf(event.delivery_event_status_id) >= 0
      );
      this.scrollDeliveriesTo = inProgressEvent;
      const driverPosition = this.driver?.position;
      const deliveryPosition = inProgressEvent?.position;
      if (driverPosition && deliveryPosition) {
        path.push(deliveryPosition);
        path.push(driverPosition);
      } else {
        events.map(({ position }) => {
          if (position) path.push(position);
        });
      }
      if (path.length === 1) this.map.panTo(path[0]);
      else this.map.autoZoom(path, -1);
    },

    onDeliveriesUpdate({ data: { events = [], coded_route }, action }) {
      const focusedDelivery = this.$store.getters['map/focusedDelivery'];
      this.showAllDrivers = false;
      this.manifestRoute = coded_route;
      const deliveries = events.filter((event) => event.position);
      if ((deliveries.length && this.isFirstUpdate.deliveries) || action?.center) {
        this.autoZoomDeliveries(deliveries);
        this.isFirstUpdate.deliveries = false;
        if (deliveries[0]?.delivery_manifest_id === focusedDelivery?.delivery_manifest_id) {
          setTimeout(() => {
            this.onFocusToDelivery(focusedDelivery);
            this.$store.dispatch('map/focusedDelivery', null);
          }, 100);
        }
      }
      this.deliveries.markers = deliveries;
    },
    onFocusToManifest({ manifest, delivery }) {
      this.onDeliveriesUpdate({ data: manifest });
      setTimeout(() => {
        this.onFocusToDelivery(delivery);
      }, 100);
    },

    onFocusToDelivery({ id, position, ...rest }) {
      if (!position) {
        const lat = rest.destination_latitude;
        const lng = rest.destination_longitude;
        position = { lat, lng };
      }
      if (position) {
        this.deliveries.infowindowId = id;
        // this.map.setZoom(17);
        this.map.panTo(position);
      } else {
        console.warn(':::POSITION NEEDED');
      }
    },
    onFocusToMarker(marker = {}) {
      const { lat, lng, zoom } = marker;
      const position = { lat, lng };
      if (lat && lng) {
        this.UnassignedDeliveriesMarker = { position, ...marker };
        zoom && this.map.setZoom(zoom);
        this.map.panTo(position);
      } else {
        this.UnassignedDeliveriesMarker = null;
      }
    },
    onFocusToDriver(driver) {
      if (driver.position) {
        this.drivers.infowindowId = driver.id;
        // this.map.setZoom(17);
        this.map.panTo(driver.position);
      }
    },
    setDashboardMarkers(deliveries, allowAutoZoom) {
      this.dashboard.markers = deliveries;
      if (allowAutoZoom) {
        this.map.autoZoom(deliveries.map((delivery) => delivery.position));
      }
    },
    panToDrivers() {
      let path = [];
      this.drivers.markers.map(({ position }) => {
        if (position) path.push(position);
      });
      this.map.autoZoom(path, -1);
    },
    resetMap() {
      this.onDeliveriesUpdate({ data: { coded_route: '', events: [] } });
      this.panToDrivers();
      this.showAllDrivers = true;
      // this.isFirstUpdate = { deliveries: true, drivers: true };
      this.UnassignedDeliveriesMarker = null;
      this.manifestRoute = null;
      this.resetInfowindow();
    },
    resetInfowindow() {
      this.drivers.infowindowId = null;
      this.deliveries.infowindowId = null;
      this.dashboard.infowindowId = null;
    }
  },
  watch: {
    manifestId(val) {
      if (val) {
        this.isFirstUpdate.deliveries = true;
        this.resetInfowindow();
      } else this.resetMap();
    }
  },
  props: {
    darkMode: { type: Boolean, default: false },
    update: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#map
  height: 100vh
  th
    font-size: 1rem
    text-align: center
    padding-bottom: 10px
.copy-clip
  ::v-deep
    .link
      margin-top: 0
.is-driver-panel-open
  .gm-ui-hover-effect
    background: none !important
  ::v-deep
    .gm-bundled-control-on-bottom
      .gm-svpc,
      .gmnoprint
        transition: all 0.5s ease
        transform: translate(-450px, 0)
</style>
