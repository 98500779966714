<template>
  <NMarker
    v-if="data"
    :zIndex="10"
    :position="data.position"
    icon="circle-medium"
    iconColor="#5265F7"
    autoOpenInfowindow
  >
    <template #infowindow v-if="data.text">
      <div class="flex f-column">
        {{ data.text }}
        <Link class="remove" @click="$emit('removed')">{{ $t('global.remove') }}</Link>
      </div>
    </template>
  </NMarker>
</template>

<script>
import { NMarker } from '@/components/Map/Plugins';
import { Link } from '@/components';

export default {
  components: {
    Link,
    NMarker
  },
  props: {
    data: { type: Object, default: null }
  }
};
</script>
<style lang="sass" scoped>
.remove
  align-self: flex-end
</style>
