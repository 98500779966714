<template>
  <CollapseCard
    id="collapseGPS"
    icon="map-marker"
    title="GPS"
    :open="open"
    @toggle="(open) => $emit('update:open', open)"
    :loading="init && loading ? true : false"
    :maxHeight="510"
  >
    <div>
      <!-- <b-button @click="getGPSs"></b-button> -->
      <GPS-list
        :GPSs="GPSs.tablet"
        title="Tablet"
        model="personName"
        theme="primary"
        @input="onTablet"
      />
    </div>
  </CollapseCard>
</template>

<script>
import CollapseCard from '../CollapseCard.vue';
import GPSList from './GPSList.vue';

export default {
  components: {
    CollapseCard,
    GPSList
  },
  destroyed() {
    this.$emit('tabletChange', []);
  },
  data() {
    return { ax: null, GPSs: { tablet: [] }, init: true, loading: 0 };
  },
  methods: {
    filterBy(dataset, field, value) {
      return this.GPSs[dataset].filter((d) => d[field] == value)[0];
    },
    onTablet(GPSs = this.GPSs.tablet, GPS) {
      this.$emit('tabletChange', { GPSs, GPS });
    },
    gpsIcon(type, GPS) {
      GPS.date;
      const diffSeconds = (new Date().getTime() - GPS.date) / 1000;
      let icon = require(`@/assets/img/markers/marker-${type}.png`);
      if (diffSeconds > 120) icon = require(`@/assets/img/markers/marker-${type}-red.png`);
      return icon;
    }
  },
  watch: {
    loading(val) {
      if (val == 0) this.init = false;
    },
    tabletFocus(id) {
      console.log('tabletFocus', id);
    }
  },
  props: {
    open: { type: Boolean, default: true },
    tabletFocus: { type: Number, default: null },
    value: { type: Array, default: () => [] }
  }
};
</script>

<style lang="sass" scoped>
#collapseGPS
  ::v-deep
    .card-content
      padding-right: 0
      &>.b-skeleton
        width: calc(100% - 20px)
.card-header
  padding-left: 20px
  .card-header-title
    padding-left: 0
.c-icon
  align-self: center
  margin-right: 10px
</style>
