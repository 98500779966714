<template>
  <section class="collapsePanel">
    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open.sync="open">
      <template #trigger="props">
        <div class="card-header" role="button" aria-controls="contentIdForA11y3">
          <div class="w-100 flex f-column">
            <div class="card-header-title">{{ title }}</div>
            <div v-if="subTitle" class="card-header-subTitle">{{ subTitle }}</div>
          </div>
          <a class="card-header-icon" v-if="searchActived && open" @click.stop="() => stopExpand()">
            <b-icon :icon="expand ? 'arrow-expand' : 'arrow-collapse'"></b-icon>
          </a>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"> </b-icon>
          </a>
        </div>
      </template>
      <div v-if="searchActived" :class="expand ? 'card-content-expand' : 'card-content'">
        <div class="content flex f-column">
          <slot name="filters" />
          <slot />
        </div>
      </div>

      <div v-else class="card-content">
        <div class="content flex f-column">
          <SearchField
            v-if="!searchActived"
            :useDropdown="false"
            v-model="iSearch"
            :label="false"
            :placeholder="fieldPlaceholder"
            :queryKey="queryKey"
          />
          <slot name="filters" />
          <slot />
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import SearchField from '@/components/SearchField.vue';
export default {
  data() {
    return {
      iSearch: '',
      open: this.isOpen,
      expand: this.isExpandValue
    };
  },
  components: {
    SearchField
  },
  methods: {
    stopExpand() {
      this.expand = !this.expand;
    }
  },
  watch: {
    iSearch(value) {
      this.$emit('search', value);
    },
    search(value) {
      this.iSearch = value;
    },
    open(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    fieldPlaceholder: { type: String, default: '' },
    isOpen: { type: Boolean, default: false },
    search: { type: String, default: '' },
    queryKey: { type: String, default: '' },
    title: { type: String, default: 'CollapsePanel' },
    subTitle: { type: String, default: '' },
    searchActived: { type: Boolean, default: false },
    isExpandValue: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.collapsePanel
  position: absolute
  width: 448px
  z-index: 1
  box-shadow: 0px 8px 24px rgba(24, 56, 153, 0.12)
.card
  border-radius: 0
  background: $main-background
.card-header
  padding: 5px 20px 0
  border: none!important
.card-header-title
  padding: 0
  font-size: $f-xl
.card-header-subTitle
  color: $gray-500
  font-weight: bold
  margin-top: -5px
.card-header-icon
  color: $primary
.card-content
  padding: .5em 1em 1em
  height: calc(100vh - 54px)
.card-content-expand
  min-height: auto
  padding: .5em 1em 1em
.content
  height: 100%
.search-field
  margin-bottom: 10px
</style>
