/* eslint-disable prettier/prettier */
export default [
  '#2cd3ec','#013da9','#8460c5','#e78a2b','#5eec8a','#2677fa','#ea5343',
  '#aa53c6','#7e9c00','#9989ff','#e392ef','#4ab35a','#fb5485','#3a98ff',
  '#b53a0b','#f3a020','#d87ecd','#78eaa7','#d73e9e','#d545d9','#43d2c0',
  '#dabce9','#ba3237','#f89464','#2c7ff0','#84c67f','#8a7523','#538678',
  '#4798c9','#db9d36','#bf0050','#91cc5d','#b18bdb','#e83146','#b5e840',
  '#616dc6','#e37842','#5092db','#dcb869','#eb827c','#ff6d5d','#a5378a',
  '#89a34d','#01b09f','#ff7fb3','#e19b5e','#ff5177','#cea8c4','#74e84a','#d6e8a1'
];
