<template>
  <div class="menu">
    <!--
    <div class="search-field">
      <Autocomplete
        :api="{
          url: `/catalogs/counties`
        }"
        :value="filter.name"
        :label="`${$t('global.searchBy')}: ${$t('map.layers.Counties')}`"
        @select="
          (data) => {
            filter.id = data.id;
          }
        "
        field="county_area"
        required
      />
      <Autocomplete
        :api="{
          url: `/destinations/categories`,
          full: true
        }"
        v-model="filter.category"
        :label="`${$t('global.searchBy')}: ${$t('map.layers.Counties')}`"
        model="category"
        field="category"
        clearable
      />
      <Map-layers :filter="filter" @activeLayers="(data) => $emit('activeLayers', data)" />
    </div>-->
    <slot />
  </div>
</template>

<script>
// import Autocomplete from '@/components/Autocomplete.vue';
// import MapLayers from './Layers.vue';

export default {
  components: {
    // Autocomplete,
    // MapLayers
  },
  data() {
    return {
      filter: { state_code: 'CA', county_area: 'SAN DIEGO COUNTY' },
      search: ''
    };
  },
  methods: {
    onInput() {}
  },
  props: {}
};
</script>

<style lang="sass" scoped>
.menu
  min-width: 400px
  max-width: 400px
  height: calc(100vh - 80px)
  background: $main-background
  padding: 20px
</style>
