var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ShareRx',{attrs:{"isOpen":_vm.shareRx.isOpen,"deliveryId":_vm.eventId,"trackingToken":_vm.trackingToken},on:{"update:isOpen":function($event){return _vm.$set(_vm.shareRx, "isOpen", $event)},"update:is-open":function($event){return _vm.$set(_vm.shareRx, "isOpen", $event)}}}),_c('CollapsePanel',{attrs:{"title":((_vm.$t('deliveries.status')) + " - " + (_vm.manifestId
        ? _vm.$t('schedule.manifest.label')
        : _vm.search
        ? _vm.$t('global.searcher')
        : _vm.$t('global.timeline'))),"subTitle":_vm.driver.employee_name,"id":"deliveryStatus","fieldPlaceholder":_vm.$t('dispatch.deliverySearch'),"queryKey":"deliveryQuery","isOpen":"","search":_vm.search},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"filters"},[_c('div',{staticClass:"flex f-jsb"},[_c('div',{staticClass:"w-100"},[_c('label',[_vm._v(_vm._s(_vm.$t('global.filterBy')))]),_c('Dropdown',{attrs:{"items":_vm.availableFilters,"model":"name","showLabel":false,"placeholder":_vm.filtersPlaceholder},on:{"click":_vm.onFilterSelect}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.iManifestId),expression:"!iManifestId"}],staticClass:"w-100"},[(_vm.hasPermission)?_c('Autocomplete',{staticClass:"mr-4 mb-1",attrs:{"placeholder":_vm.$t('global.placeholder.select', [_vm.$tc('run.driver')]),"api":{
                url: 'employees',
                query: 'full_name',
                params: [
                  { id: 'is_active', value: 1 },
                  { id: 'user_type_name', value: 'Driver' }
                ],
                full: true
              },"field":"full_name","size":"is-small","clearOnSelect":""},on:{"select":_vm.onSelectDriver}}):_vm._e()],1),_c('div',[_c('Link',{attrs:{"underlined":"","disabled":!_vm.isClearAllowed},on:{"click":_vm.onClear}},[_vm._v(_vm._s(_vm.$t('button.clear')))])],1)]),_vm._l((_vm.activeFilters),function(filter){return _c('b-tag',{key:filter.uuid,staticClass:"mr-1 mb-1",attrs:{"type":"is-primary","closable":"","aria-close-label":"Close tag"},on:{"close":function($event){return _vm.onRemoveFilter(filter.uuid)}}},[_vm._v(" "+_vm._s(filter.name)+" ")])})],2)]},proxy:true}])},[_c('div',{staticClass:"deliveries"},[_c('DeliveryStatusByManifest',{directives:[{name:"show",rawName:"v-show",value:(_vm.iManifestId),expression:"iManifestId"}],ref:"deliveryStatusByManifest",attrs:{"manifestId":_vm.iManifestId},on:{"update":_vm.onDeliveryStatusByManifestUpdate}},[(_vm.filteredManifestEvents.length)?_vm._l((_vm.filteredManifestEvents),function(delivery){return _c('DeliveryStatusCard',{key:("delivery-" + (delivery.id)),attrs:{"delivery":delivery,"totalDeliveryMessage":_vm.totalDeliveryMessage,"isManifestCard":"","driverName":_vm.driver.employee_name},on:{"shareRx":_vm.onShareRx,"focusToDelivery":function () { return _vm.$emit('focusToDelivery', delivery); },"statusTagClick":_vm.onStatusTagClick}})}):_c('Empty')],2),_c('InfiniteScroll',{directives:[{name:"show",rawName:"v-show",value:(!_vm.iManifestId),expression:"!iManifestId"}],attrs:{"api":"/delivery_manifest/delivery_events","perPage":40,"paused":_vm.isFiltered,"loading":_vm.timelineLoading},on:{"update":function (ref) {
                  var items = ref.items;

                  return _vm.setData(items);
},"update:loading":function($event){_vm.timelineLoading=$event}}},[(_vm.deliveriesBySearchLoading)?_c('div',{staticClass:"deliveriesBySearchLoading"},[_c('b-loading',{attrs:{"is-full-page":false,"active":true}})],1):_vm._e(),(_vm.filteredDeliveries.length || _vm.deliveriesLog.length)?[(_vm.isFiltered && !_vm.deliveriesBySearchLoading)?_c('DeliveryStatusCardRendered',{attrs:{"value":_vm.filteredDeliveries},on:{"shareRx":_vm.onShareRx,"statusTagClick":_vm.onStatusTagClick,"orderNumberClick":_vm.onSearch}}):_vm._e(),_c('DeliveryStatusCardRendered',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFiltered),expression:"!isFiltered"}],attrs:{"value":_vm.deliveriesLog},on:{"shareRx":_vm.onShareRx,"statusTagClick":_vm.onStatusTagClick,"orderNumberClick":_vm.onSearch}})]:(!_vm.timelineLoading)?_c('Empty'):_vm._e()],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }