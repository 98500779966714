<template>
  <div>
    <ShareRx :isOpen.sync="shareRx.isOpen" :deliveryId="eventId" :trackingToken="trackingToken" />
    <CollapsePanel
      :title="`${$t('deliveries.status')} - ${
        manifestId
          ? $t('schedule.manifest.label')
          : search
          ? $t('global.searcher')
          : $t('global.timeline')
      }`"
      :subTitle="driver.employee_name"
      id="deliveryStatus"
      :fieldPlaceholder="$t('dispatch.deliverySearch')"
      queryKey="deliveryQuery"
      isOpen
      :search="search"
      @search="onSearch"
    >
      <template #filters>
        <div class="filters">
          <div class="flex f-jsb">
            <div class="w-100">
              <label>{{ $t('global.filterBy') }}</label>
              <Dropdown
                :items="availableFilters"
                model="name"
                @click="onFilterSelect"
                :showLabel="false"
                :placeholder="filtersPlaceholder"
              />
            </div>
            <div class="w-100" v-show="!iManifestId">
              <Autocomplete
                v-if="hasPermission"
                :placeholder="$t('global.placeholder.select', [$tc('run.driver')])"
                class="mr-4 mb-1"
                :api="{
                  url: 'employees',
                  query: 'full_name',
                  params: [
                    { id: 'is_active', value: 1 },
                    { id: 'user_type_name', value: 'Driver' }
                  ],
                  full: true
                }"
                field="full_name"
                size="is-small"
                clearOnSelect
                @select="onSelectDriver"
              />
            </div>
            <div>
              <Link underlined @click="onClear" :disabled="!isClearAllowed">{{
                $t('button.clear')
              }}</Link>
            </div>
          </div>
          <b-tag
            v-for="filter in activeFilters"
            class="mr-1 mb-1"
            :key="filter.uuid"
            type="is-primary"
            closable
            aria-close-label="Close tag"
            @close="onRemoveFilter(filter.uuid)"
          >
            {{ filter.name }}
          </b-tag>
        </div>
      </template>
      <div class="deliveries">
        <DeliveryStatusByManifest
          v-show="iManifestId"
          :manifestId="iManifestId"
          @update="onDeliveryStatusByManifestUpdate"
          ref="deliveryStatusByManifest"
        >
          <template v-if="filteredManifestEvents.length">
            <DeliveryStatusCard
              v-for="delivery in filteredManifestEvents"
              :key="`delivery-${delivery.id}`"
              :delivery="delivery"
              @shareRx="onShareRx"
              @focusToDelivery="() => $emit('focusToDelivery', delivery)"
              @statusTagClick="onStatusTagClick"
              :totalDeliveryMessage="totalDeliveryMessage"
              isManifestCard
              :driverName="driver.employee_name"
            />
          </template>
          <Empty v-else />
        </DeliveryStatusByManifest>
        <InfiniteScroll
          v-show="!iManifestId"
          api="/delivery_manifest/delivery_events"
          @update="({ items }) => setData(items)"
          :perPage="40"
          :paused="isFiltered"
          :loading.sync="timelineLoading"
        >
          <div class="deliveriesBySearchLoading" v-if="deliveriesBySearchLoading">
            <b-loading :is-full-page="false" :active="true" />
          </div>
          <template v-if="filteredDeliveries.length || deliveriesLog.length">
            <DeliveryStatusCardRendered
              v-if="isFiltered && !deliveriesBySearchLoading"
              :value="filteredDeliveries"
              @shareRx="onShareRx"
              @statusTagClick="onStatusTagClick"
              @orderNumberClick="onSearch"
            />
            <DeliveryStatusCardRendered
              v-show="!isFiltered"
              :value="deliveriesLog"
              @shareRx="onShareRx"
              @statusTagClick="onStatusTagClick"
              @orderNumberClick="onSearch"
            />
          </template>
          <Empty v-else-if="!timelineLoading" />
        </InfiniteScroll>
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { generatePositionLatLng } from '@/utils/MapFunctions';
import { ShareRx } from '@/components/Dispatch/Modals';
import CollapsePanel from './CollapsePanel.vue';
import DeliveryStatusCard from './DeliveryStatusCard.vue';
import DeliveryStatusCardRendered from './DeliveryStatusCardRendered.vue';
import DeliveryStatusByManifest from './DeliveryStatusByManifest.vue';
import { Autocomplete, Dropdown, Empty, InfiniteScroll, Link } from '@/components';
import { getTypeData } from '../DeliveryTypes';
import { sortBy, compose, toLower, prop } from 'ramda';
import { Permissions } from '@/utils/Secure';
import { clone } from 'ramda';

export default {
  components: {
    Autocomplete,
    CollapsePanel,
    DeliveryStatusByManifest,
    DeliveryStatusCard,
    DeliveryStatusCardRendered,
    Dropdown,
    Empty,
    InfiniteScroll,
    Link,
    ShareRx
  },
  mounted() {
    this.init();
  },
  destroyed() {
    clearTimeout(this.getDataTimeout);
  },
  created() {
    this.$store.subscribe(({ type }, { map: { driverStatusTagClick, timelineManifest } }) => {
      if (type === 'map/timelineManifest') {
        this.timelineManifestId = timelineManifest?.manifest?.id;
      }
      if (type === 'map/driverStatusTagClick') {
        if (driverStatusTagClick === 4) {
          this.activeFilters = [getTypeData({ id: 2 })];
        }
      }
    });
  },
  data() {
    const date = this.$moment().hours(0).minutes(0).seconds(0).utc();
    const lastUpdate = date.format('Y-MM-DD HH:mm:ss');
    return {
      deliveriesLog: [],
      deliveriesBySearchLoading: false,
      deliveriesBySearch: [],
      timelineLoading: false,
      timelineManifestId: null,
      iManifestId: this.manifestId,
      manifestData: {},
      updateInterval: 15,
      // .----
      currentSort: {},
      eventId: null,
      filters: [],
      activeFilters: [],
      getDataTimeout: null,
      search: '',
      lastUpdate,
      shareRx: { isOpen: false },
      trackingToken: null
    };
  },
  computed: {
    isTimeline() {
      return !this.timelineManifestId && !this.manifestId;
    },
    isTimelineSearch() {
      return this.isTimeline && !!this.search;
    },
    isFiltered() {
      return !!this.activeFilters.length || !!this.search;
    },
    hasPermission() {
      let isAllowed = false;
      if (Permissions.Employees.readAll?.length) {
        isAllowed = Permissions.Employees.readAll.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.Employees.readAll);
    },
    totalDeliveryMessage() {
      return `${this.manifestData.total_deliveries_pickedup}/${this.manifestData.total_deliveries}`;
    },
    isClearAllowed() {
      return (
        !!this.manifestId ||
        !!this.activeFilters.length ||
        !!this.search ||
        !!this.timelineManifestId
      );
    },
    filteredManifestEvents() {
      let events = this.manifestData?.events || [];
      this.lastUpdate;
      const { activeFilters } = this;
      if (activeFilters.length) {
        events = events.filter((D) => {
          return activeFilters.some((filter) => {
            switch (filter.strId) {
              case 'unsuccessful':
                return [6, 7, 11, 16, 17].includes(D.delivery_event_status_id);
              case 're-scheduled':
                return [5, 15].includes(D.delivery_event_status_id);
              case 'attempted':
                return [3, 14].includes(D.delivery_event_status_id);
              case 'in-progress':
                return [2, 13].includes(D.delivery_event_status_id);
              case 'scheduled':
                return [1, 12].includes(D.delivery_event_status_id);
              default:
                return (
                  filter.delivery_event_type_id === D.delivery_event_type_id ||
                  filter.delivery_event_status_id === D.delivery_event_status_id
                );
            }
          });
        });
      }

      if (this.search)
        events = events.filter((D) => {
          let found = false;
          const strSearch = this.search.toLowerCase();
          Object.keys(D).map((k) => {
            const strValue = String(D[k]).toLowerCase();
            if (strValue.search(strSearch) != -1) found = true;
          });
          if (found) return D;
        });
      return events;
    },
    availableFilters() {
      this.updateFilters();
      let strIdArray = [];

      this.activeFilters.map((e) => {
        strIdArray = [...strIdArray, e.strId];
      });

      let filters = this.filters.filter(
        ({ uuid, strId, delivery_event_status_id, delivery_event_type_id }) => {
          if (!this.activeFilters.filter((AF) => AF.uuid === uuid)[0]) {
            if (
              !strIdArray.includes(strId) &&
              delivery_event_type_id !== 19 &&
              delivery_event_status_id !== 19
            ) {
              strIdArray = [...strIdArray, strId];
              return true;
            } else if (uuid === 'e8' || uuid === 's18') return true;
          }
        }
      );

      if (this.manifestId) {
        filters.map((e) => {
          if (e.uuid === 'e8') e.name = 'Collects';
        });
      } else filters = filters.filter((e) => e.uuid !== 'e8');

      const unsuccessfulIds = [6, 7, 10, 11, 16, 17];

      if (
        filters.find((e) => unsuccessfulIds.includes(e.delivery_event_type_id)) ||
        filters.find((e) => unsuccessfulIds.includes(e.delivery_event_status_id))
      )
        filters.unshift({
          uuid: 'unsuccessful',
          name: this.$t('global.unsuccessful'),
          strId: 'unsuccessful'
        });

      return filters;
    },
    sortItems() {
      return [
        { uuid: '-', value: '-' },
        { uuid: 'participant_name', value: this.$t('participant.name') },
        { uuid: 'eta', value: this.$t('schedule.manifest.eta.short') }
      ];
    },
    filtersPlaceholder() {
      const total = this.activeFilters.length;
      if (total) {
        return this.$tc('global.filter', total);
      }
      return this.$t('global.select');
    },
    sortedDeliveries() {
      const { uuid } = this.currentSort;
      const sortByKey = sortBy(compose(toLower, prop(uuid)));
      switch (uuid) {
        case '-':
          return this.filteredDeliveries;
        default:
          return sortByKey(this.filteredDeliveries);
      }
    },
    filteredDeliveries() {
      const { activeFilters, search } = this;
      let deliveries = search ? this.deliveriesBySearch : this.deliveriesLog;
      const driver = activeFilters.find((e) => e.uuid === 'driver');
      if (driver) {
        const currentDeliveries = deliveries.filter((delivery) => {
          if (delivery.assigned_employee_id === driver.id) return delivery;
        });
        deliveries = currentDeliveries;
      }
      const filterWithoutDriver = activeFilters.slice();
      const index = filterWithoutDriver.map((e) => e.uuid).indexOf('driver');
      if (index > -1) filterWithoutDriver.splice(index, 1);

      if (filterWithoutDriver.length) {
        deliveries = deliveries.filter((D) => {
          return filterWithoutDriver.some((filter) => {
            if (D.assigned_employee_id === filter.id) return true;

            switch (filter.strId) {
              case 'unsuccessful':
                return [6, 7, 11, 16, 17].includes(D.delivery_event_status_id);
              case 're-scheduled':
                return [5, 15].includes(D.delivery_event_status_id);
              case 'attempted':
                return [3, 14].includes(D.delivery_event_status_id);
              case 'in-progress':
                return [2, 13].includes(D.delivery_event_status_id);
              case 'scheduled':
                return [1, 12].includes(D.delivery_event_status_id);
              default:
                return (
                  filter.delivery_event_type_id === D.delivery_event_type_id ||
                  filter.delivery_event_status_id === D.delivery_event_status_id
                );
            }
          });
        });
      }
      return deliveries || [];
    }
  },
  methods: {
    init() {
      this.setNextUpdate();
    },
    focusToLastSuccessful() {
      const hasPickupEvent = this.manifestData.events.some((e) => {
        const isDelivery = e.delivery_event_type_id === 3;
        const isCollect = e.delivery_event_type_id === 8;

        if (isDelivery) return [1, 2, 3, 8].includes(e.delivery_event_status_id);
        if (isCollect) return [12, 13, 14, 18].includes(e.delivery_event_status_id);
      });
      if (!hasPickupEvent) return;

      const eventCards = this.$refs?.deliveryStatusByManifest?.$children || [];
      const lastDone = eventCards.findLast((card) => !!card.isSuccesful);
      if (lastDone) lastDone.$el.scrollIntoView({ block: 'start' });
    },
    onSelectDriver(D) {
      if (!D) return;
      this.onFilterSelect({ uuid: 'driver', name: D.full_name, id: D.id });
    },
    emitUpdate(action = {}) {
      const data = clone(this.manifestData);
      if (this.activeFilters.length) data.events = this.filteredManifestEvents;
      this.$emit('update', { data, action });
    },
    onDeliveryStatusByManifestUpdate(data) {
      const { isAutoUpdate, ...mData } = data;
      this.manifestData = mData;
      this.emitUpdate();
      this.$nextTick(() => {
        if (!isAutoUpdate) this.focusToLastSuccessful();
      });
    },
    async getDeiveriesBySearch(search) {
      this.deliveriesBySearchLoading = true;
      const { data } = await this.Api.get(
        `/delivery_manifest/event_transitions/search?all=${search}`
      );
      this.deliveriesBySearch = data.map((d) => d._source);
      this.deliveriesBySearchLoading = false;
    },
    async getData() {
      try {
        const { data } = await this.Api.get(
          `/delivery_manifest/delivery_events_statuses?last_update=${this.lastUpdate}`
        );
        this.setData(data, true);
      } catch (error) {
        console.log(error);
      }
      this.setNextUpdate();
    },
    onFilterSelect(filter) {
      if (!filter) return;
      if (filter.uuid == 'driver') {
        const index = this.activeFilters.findIndex((AF) => AF.uuid == 'driver');
        if (index > -1) this.activeFilters.splice(index, 1);
        this.activeFilters.push(filter);
      } else if (!this.activeFilters.filter((AF) => AF.uuid === filter.uuid)[0])
        this.activeFilters.push(filter);
      this.handleUpdateByFilters();
    },
    onStatusTagClick(status) {
      this.onFilterSelect(status);
    },
    onSearch(search) {
      this.search = search;
      if (this.isTimeline) {
        if (search) this.getDeiveriesBySearch(search);
      }
    },
    handleUpdateByFilters() {
      if (this.iManifestId) this.emitUpdate({ center: true });
    },
    onClear() {
      this.search = '';
      if (this.activeFilters.length) {
        this.activeFilters = [];
        this.handleUpdateByFilters();
      } else if (this.iManifestId) {
        this.$store.dispatch('map/manifestId', null);
        this.iManifestId = null;
      } else {
        this.$store.dispatch('map/setTimelineManifest', null);
      }
    },
    onRemoveFilter(uuid) {
      this.activeFilters = this.activeFilters.filter((filter) => filter.uuid !== uuid);
      this.handleUpdateByFilters();
    },
    setData(updates = [], isLive) {
      if (isLive) updates = updates.reverse();
      generatePositionLatLng(updates, {
        lat: 'destination_latitude',
        lng: 'destination_longitude'
      });
      updates.map((u) => {
        const matchingIndex = this.deliveriesLog.findIndex((d) =>
          ['id', 'event_transition_datetime'].every((key) => d[key] === u[key])
        );
        if (matchingIndex === -1) {
          if (isLive) this.deliveriesLog.unshift(u);
          else this.deliveriesLog.push(u);
        }
      });

      this.updateFilters();
    },
    setNextUpdate() {
      this.setLastUpdate();
      this.getDataTimeout = setTimeout(() => {
        this.getData();
      }, this.updateInterval * 1000);
    },
    setLastUpdate() {
      this.lastUpdate = this.$moment().utc().format('Y-MM-DD HH:mm:ss');
    },
    filtersFilterByKey({ key, value }) {
      return this.filters.filter((F) => F[key] === value)[0];
    },
    updateFilters() {
      const { filters, isTimelineSearch, deliveriesBySearch, deliveriesLog } = this;
      const timelineDeliveries = isTimelineSearch ? deliveriesBySearch : deliveriesLog;
      let statusIds = [];
      let typesIds = [];

      statusIds = [
        ...new Set(
          (this.manifestId ? this.filteredManifestEvents : timelineDeliveries).map(
            (D) => D.delivery_event_status_id
          )
        )
      ].sort();

      typesIds = [
        ...new Set(
          (this.manifestId ? this.filteredManifestEvents : timelineDeliveries).map(
            (D) => D.delivery_event_type_id
          )
        )
      ]
        .sort()
        .filter((f) => f !== 3);

      const status = typesIds.map((id) => getTypeData({ id, useEventTypes: true, exclude: [3] }));
      let types = statusIds.map((id) => getTypeData({ id }));
      types = types.filter((type) => type.uuid !== 's8');

      const newFilters = [...status, ...types].filter(
        (newFilter) => !filters.filter((filter) => filter.uuid === newFilter.uuid)[0]
      );
      this.filters = [...filters, ...newFilters];
    },
    onShareRx(args) {
      this.eventId = args.delivery_id;
      this.trackingToken = args.delivery_tracking_token;
      this.shareRx.isOpen = true;
    }
  },
  watch: {
    manifestData(value) {
      if (value.events.length >= 1) this.updateFilters();
    },
    manifestId(value) {
      this.iManifestId = value;
      this.search = '';
      this.filters = [];
    },
    scrollTo(value, old) {
      console.log('::da scrollTo', value, old);
    }
  },
  props: {
    driver: { type: Object, default: () => ({}) },
    manifestId: { type: Number, default: 0 },
    scrollTo: { type: Object, default: () => ({}) }
  }
};
</script>

<style lang="sass" scoped>
#deliveryStatus
  border-bottom-right-radius: $br-md
  overflow: hidden
  left: 0
  .deliveriesBySearchLoading
    position: relative
    height: 100%
  .deliveries
    overflow: auto
    margin: 0 -10px
    height: 100%
    .infinite-scroll
      padding-top: 2px
  .filters
    color: $primary
    margin-bottom: 15px

    label
      font-weight: bold
      margin-right: 15px
</style>

<style lang="sass">
.dark
  #deliveryStatus .filters
    color: $primary-dark
</style>
