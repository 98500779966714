<template>
  <b-collapse
    class="card"
    :open="open"
    animation="slide"
    @open="$emit('toggle', true)"
    @close="$emit('toggle', false)"
  >
    <template #trigger="props">
      <div class="card-header" role="button">
        <b-icon v-if="icon" class="c-icon" :icon="icon" />
        <p class="card-header-title">{{ title }}</p>
        <b-icon v-if="loading" class="c-icon" icon="loading" custom-class="mdi-spin" />
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
      </div>
    </template>
    <div
      class="card-content"
      :class="overflow ? 'overflow' : ''"
      :style="`max-height:${maxHeight}px;`"
    >
      <template v-if="loading">
        <b-skeleton v-for="i in 7" height="20" :key="i" />
      </template>
      <slot v-else />
    </div>
  </b-collapse>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: '' },
    open: { type: Boolean, default: true },
    overflow: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    maxHeight: { type: Number, default: 300 },
    title: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.card
  margin-bottom: 14px
.card-header
  padding-left: 20px
  .card-header-title
    padding-left: 0
.c-icon
  align-self: center
  margin-right: 10px
.card-content
  padding-top: 10px
  padding-bottom: 10px
.overflow
  overflow: auto
</style>
