export function coordinatesToPath(path) {
  if (path) {
    if (typeof path[0] === 'object') return path.map((path) => coordinatesToPath(path));
    else return { lat: path[1], lng: path[0] };
  }
}
export function normalizeGeocoderAddressComponents(address_components) {
  const ShouldBeComponent = {
    number: ['street_number'],
    zipcode: ['postal_code'],
    street: ['street_address', 'route'],
    city: ['locality', 'sublocality', 'sublocality_level_1'],
    state: ['administrative_area_level_1'],
    country: ['country']
  };

  const address = {};
  for (var shouldBe in ShouldBeComponent) {
    address_components.map((component) => {
      if (address[shouldBe]) return;
      const founded = !!ShouldBeComponent[shouldBe].filter((acName) =>
        component.types.some((cName) => cName === acName)
      )[0];

      if (founded) {
        if (shouldBe === 'country' || shouldBe === 'state') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    });
  }
  return address;
}

export function generatePositionLatLng(data = [], keys = { lat: 'latitude', lng: 'longitude' }) {
  const result = data.map((obj) => {
    const lat = obj[keys.lat];
    const lng = obj[keys.lng];
    if (lat && lng) {
      obj.position = { lat, lng };
    }
    return obj;
  });
  return result;
}
