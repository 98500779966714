<template>
  <div class="deliveryStatusCard">
    <Collapse
      class="deliveryStatutes"
      :collapseId="`dsc-${delivery.id}`"
      :separator="false"
      :disabled="!!isCompact"
    >
      <template #header>
        <div class="c-header">
          <div class="column flex f-jsb f-ai f-full pb-0" :class="status.strId">
            <div class="flex f-ai">
              <b-icon v-if="useStatusIcon" class="s-icon" :icon="status.icon" custom-size=" " />
              <DeliveryStatusSignUI
                v-else
                class="secuenceIcon"
                :delivery="delivery"
                markerShowNumbers
                isMapMarker
                :isCollect="delivery.delivery_event_type_id === 8"
              />
              <DateTime
                class="strong"
                v-if="isCompact"
                :date="delivery.eta"
                :offset="delivery.time_offset"
                format="hh:mm A"
              />
              <p v-else>{{ participantName }}</p>
            </div>
            <div class="flex">
              <Link
                v-if="delivery.destination_latitude"
                :label="$t('button.showMap')"
                class="show-on-map mr-1"
                icon="map-marker-radius"
                @click.stop="onShowOnMap"
                :loading="loading.showOnMap"
              />
              <Link
                v-if="status.strId == 'in-progress'"
                :label="$t('schedule.rx')"
                icon="map-search"
                class="rx"
                @click.stop="shareRx"
                :permission="Permission.shareRX"
              />
              <RefrigeratedIconTag v-if="delivery.refrigeration_needed" />
              <DeliveryStatusSignUI
                :delivery="delivery"
                :markerShowNumbers="true"
                @status="(data) => (status = data)"
                @statusTagClick="onStatusTagClick"
                :totalDeliveryMessage="totalDeliveryMessage"
                :isCollect="delivery.delivery_event_type_id === 8"
              />
            </div>
          </div>
          <div v-if="!isManifestCard" class="c-employee flex">
            <p>{{ delivery.destination_full_address }}</p>
            <span>{{ delivery.assigned_employee_full_name }}</span>
          </div>
          <div class="c-address">
            <div v-if="isManifestCard">
              {{ delivery.destination_full_address }}
            </div>
            <div class="container-symbology">
              <DeliveryStatusUI v-if="delivery.rush" type="rush" component="tag" />
              <DeliveryStatusUI
                v-if="delivery.priority && !delivery.rush"
                type="priority"
                component="tag"
              />
              <ConstraintDatesViewer
                v-if="delivery.delivery_start_time || delivery.delivery_end_time"
                isTag
                class="c-date mb-3"
                :startTime="delivery.delivery_start_time"
                :endTime="delivery.delivery_end_time"
                :offset="delivery.time_offset"
              />
              <DeliveryStatusUI
                v-if="delivery.delivery_event_type_id === 8"
                type="collect"
                component="tag"
              />
              <DeliveryStatusUI
                v-if="delivery.leave_at_door"
                type="leave-at-door"
                component="tag"
              />
              <DeliveryStatusUI v-if="delivery.male_driver" type="male-driver" component="tag" />
              <DeliveryStatusUI
                v-if="delivery.female_driver"
                type="female-driver"
                component="tag"
              />
            </div>
            <div v-if="[6, 7, 11, 16, 17].includes(delivery.delivery_event_status_id)">
              <MessageBadge
                type="is-danger"
                :message="
                  delivery.non_delivery_reason_id !== 4
                    ? delivery.non_delivery_reason_description
                    : delivery.other_non_delivery_reason_description
                "
              />
            </div>
          </div>
          <div
            class="column c-footer flex f-full"
            :class="delivery.order_number ? 'f-jsb' : 'f-jce'"
          >
            <div v-if="isCompact && !isManifestCard">
              {{ delivery.destination_full_address }}
            </div>
            <DateTime
              v-if="delivery.event_type_is_mandatory !== 1"
              :date="delivery.event_transition_datetime || delivery.eta"
              :offset="delivery.time_offset"
              format="hh:mm A"
            />
            <CopaymentTag :value="delivery.copayment_amount" />
            <CopyTextToClipBoard
              class="order-number"
              v-if="delivery.order_number && delivery.event_type_is_mandatory !== 1"
              :copyToText="delivery.order_number"
              :text="`#${delivery.order_number}`"
            />
          </div>
        </div>
      </template>
      <div class="c-content flex f-jsb">
        <Link
          v-if="!delivery.event_type_is_mandatory"
          @click="onDownloadDeliveryReport"
          :permission="Permission.report"
          underlined
        >
          {{ $t('dispatch.downloadDeliveryReport') }}
        </Link>
        <Link
          v-if="!delivery.event_type_is_mandatory"
          @click="onViewDeliveryHistory"
          :permission="Permission.viewHistory"
          underlined
        >
          {{ $t('global.viewHistory') }}
        </Link>
      </div>
    </Collapse>
  </div>
</template>

<script>
import { generatePositionLatLng } from '@/utils/MapFunctions';
import { Permissions } from '@/utils/Secure';
import {
  Collapse,
  ConstraintDatesViewer,
  CopyTextToClipBoard,
  DateTime,
  DeliveryStatusSignUI,
  Link,
  MessageBadge,
  RefrigeratedIconTag,
  DeliveryStatusUI
} from '@/components';
import CopaymentTag from '../../CopaymentTag.vue';

export default {
  components: {
    Collapse,
    ConstraintDatesViewer,
    CopyTextToClipBoard,
    DateTime,
    DeliveryStatusSignUI,
    Link,
    MessageBadge,
    RefrigeratedIconTag,
    DeliveryStatusUI,
    CopaymentTag
  },
  computed: {
    isSuccesful() {
      const { delivery_event_type_id, delivery_event_status_id } = this.delivery;

      const isDeliverySuccess = delivery_event_type_id === 3 && delivery_event_status_id === 4;
      const isCollectSuccess = delivery_event_type_id === 8 && delivery_event_status_id === 18;

      return isDeliverySuccess || isCollectSuccess;
    },
    participantName() {
      return this.delivery.participant_full_name || this.delivery.participant_name;
    },
    isCompact() {
      const { delivery_event_type_id, event_type_is_mandatory } = this.delivery;
      const compacts = [1, 2, 4, 5, 7];
      return event_type_is_mandatory || compacts.indexOf(delivery_event_type_id) + 1;
    },
    Permission() {
      return Permissions.Dispatch;
    }
  },
  data() {
    return {
      loading: { showOnMap: false },
      status: {}
    };
  },
  methods: {
    shareRx() {
      this.$emit('shareRx', {
        delivery_id: this.delivery.id,
        delivery_tracking_token: this.delivery.tracking_token
      });
    },
    async onShowOnMap() {
      const { delivery } = this;
      if (this.isManifestCard) {
        this.$emit('focusToDelivery', delivery);
      } else {
        this.loading.showOnMap = true;
        this.Api.cancelRequest({ regexRoute: /get:delivery_manifest\/\d+/ });
        try {
          const { data: manifest } = await this.Api.get(
            `delivery_manifest/${this.delivery.delivery_manifest_id}`
          );
          manifest.events = generatePositionLatLng(manifest.events, {
            lat: 'destination_latitude',
            lng: 'destination_longitude'
          });
          this.$store.dispatch('map/setTimelineManifest', { manifest, delivery });
        } catch (Err) {
          console.error(Err.error);
        }
        this.loading.showOnMap = false;
      }
    },
    onStatusTagClick() {
      this.$emit('statusTagClick', this.status);
    },
    onDownloadDeliveryReport() {
      let { href } = this.$router.resolve({
        name: 'deliveryReport',
        params: { eventId: this.delivery.id }
      });
      window.open(href, '_blank');
    },
    onViewDeliveryHistory() {
      this.$store.commit('dispatch/deliveryHistory', {
        showModal: true,
        hasDeliveryData: true,
        ...this.delivery
      });
    }
  },
  props: {
    delivery: { type: Object, default: () => {} },
    isManifestCard: { type: Boolean, default: false },
    useStatusIcon: { type: Boolean, default: false },
    totalDeliveryMessage: { type: String, default: null },
    driverName: { type: String, default: null }
  }
};
</script>

<style lang="sass" scoped>
.deliveryStatutes
  &:hover
    .show-on-map
      opacity: 1
  ::v-deep
    .secuenceIcon
      .CustomIcon
        width: 28px!important
    .card-header
      &:hover
        // background: $gray-50
    .card-header-icon
      padding: .75rem 10px .75rem 5px
.show-on-map:not([loading=true])
  opacity: 0
.icon
  color: darken($gray-100,40)
  margin-right: 8px
  font-size: 22px
@each $i,$color in $deliveryStatusColors
  .#{$i}
    .s-icon,
    .secuence
      color: darken($color,40)
.map-search
  align-self: flex-start
  margin-right: 5px
  color: $blue-500
.rx
  margin-right: 9px
.collapse
  margin-bottom: 7px
.column
  padding: 10px 0
.c-header
  width: 100%
  .flex
    display: flex !important
.c-content
  font-weight: 700
  padding-left: 35px
.c-address
  padding: 0px 0px 0px 35px
  font-weight: 700
  font-size: $f-sm
  ::v-deep
    .tag
      max-height: 28px
.c-date
  font-size: 14px
  margin-right: 9px
.container-symbology
  display: flex
  margin-top: 0.4rem
  flex-wrap: wrap
.c-priority
  margin-bottom: 0.7rem
.c-employee
  color: $gray-500
  flex-direction: column
  padding: 0px 0 0px 35px
  font-size: $f-sm
  span
    display: block
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    text-align: right
    color: $gray-700
  p
    font-weight: bold
    color: $dark
    margin-bottom: 0px
.c-footer, .c-driver-name
  padding: 0px 0px 10px 35px
  .link
    font-weight: 700
  .order-number
    ::v-deep
      .link
        margin: 0
</style>
<style lang="sass">
.dark
  .deliveryStatusCard
    @each $i,$color in $deliveryStatusColorsDark
      .#{$i}
        .s-icon,
        .secuence
          color: $color
    .deliveryStatutes
      background: $gray-800!important
      color: $main-background
      strong
        color: $white
      &:hover
        box-shadow: 0 0 10px 0 $gray-600
      .card-header
        &:hover
          background: $gray-800
    .c-content
      a
        color: $blue-300
        &:hover
          color: $blue-400
</style>

<style lang="sass">
.dark
  .c-employee
    span
      color: $gray-300
    p
      color: $gray-200
</style>
