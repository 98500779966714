<template>
  <div>
    <CollapsePanel
      :title="$t('drivers.dashboard')"
      id="dashboard"
      queryKey="deliveryQuery"
      :isOpen.sync="oIsOpenPanel"
      searchActived
      isExpandValue
    >
      <div class="deliveries">
        <Card class="card-dashboard">
          <template #header>
            <div class="header-layers">
              <b-icon icon="layers" />
              {{ `${$t('drivers.layers')}` }}
              <div class="loading-spin">
                <b-icon
                  v-if="!loading.update && loading.spiner"
                  icon="loading"
                  custom-class="mdi-spin"
                />
              </div>
            </div>
          </template>
          <slot name="modal-body" />
          <div v-if="loading.update" class="loading-container">
            <b-loading :active="true" :is-full-page="false" />
          </div>
          <template v-else>
            <div class="tag-container">
              <iSwitch
                :text="$t('dispatch.successfulDeliveries')"
                @input="handleSwitchChange($event, 'successful', true)"
              />
              <div class="tag successful">
                <b-icon v-if="loadingSwitch.successful" icon="loading" custom-class="mdi-spin" />
                <template v-else>
                  {{ data.total_successful_deliveries }} of {{ data.total_assigned_deliveries }}
                </template>
              </div>
            </div>
            <div class="tag-container">
              <iSwitch
                :text="$t('dispatch.unsuccessfulDeliveries')"
                @input="handleSwitchChange($event, 'unsuccessful', true)"
              />
              <div class="tag canceled">
                <b-icon v-if="loadingSwitch.unsuccessful" icon="loading" custom-class="mdi-spin" />
                <template v-else>
                  {{ totalUnsuccessfulDeliveries }}
                </template>
              </div>
            </div>
            <div class="tag-container">
              <iSwitch
                :text="$t('dispatch.assignedDeliveries')"
                @input="handleSwitchChange($event, 'assigned', true)"
              />
              <div class="tag in-progress">
                <b-icon v-if="loadingSwitch.assigned" icon="loading" custom-class="mdi-spin" />
                <template v-else>
                  {{ data.total_assigned_deliveries }} of {{ data.total_deliveries_of_a_day }}
                </template>
              </div>
            </div>
            <div class="tag-container">
              <p class="inbox-text">{{ $t('dispatch.inboxDeliveries') }}</p>
              <div class="tag inbox">
                {{ dataObject.inboxDeliveries }}
              </div>
            </div>
            <hr />
            <div class="tag-container">
              <iSwitch
                :text="$t('dispatch.unassignedDeliveries')"
                v-model="showUnassigned"
                @input="handleSwitchChange($event, 'unassigned', true)"
              />
              <div class="tag re-scheduled">
                <b-icon v-if="loadingSwitch.unassigned" icon="loading" custom-class="mdi-spin" />
                <template v-else>
                  {{ dataObject.unassigned }} of {{ data.total_deliveries_of_a_day }}
                </template>
              </div>
            </div>
            <div class="tag-container">
              <b-checkbox
                :disabled="disabledRushUnassigned"
                @input="handleSwitchChange(1, 'unassigned', true, false)"
                v-model="checkedRush"
              >
                <div capitalize class="check-item">
                  {{ $t('dispatch.showRushOnly') }}
                </div>
              </b-checkbox>
              <div class="tag rush text-white">
                <b-icon
                  v-if="loadingSwitch.unassigned || loadingSwitch.unassignedRush"
                  icon="loading"
                  custom-class="mdi-spin"
                />
                <template v-else>
                  {{ data.total_unassigned_rush }}
                </template>
              </div>
            </div>
            <div class="tag-container">
              <b-checkbox
                v-model="showCdpColors"
                :disabled="!showUnassigned || loadingSwitch.unassigned"
              >
                {{ $t('cdp.showColors') }}
              </b-checkbox>
            </div>
          </template>
        </Card>
        <Card class="card-dashboard">
          <h5>{{ $t('drivers.deliveriesStatus') }}</h5>
          <div v-if="loading.update" class="loading-container">
            <b-loading :active="true" :is-full-page="false" />
          </div>
          <div id="chart" v-else>
            <apexchart
              :options="chartOptions"
              :series="series"
              height="190px"
              id="chartDashboard"
              type="donut"
              v-if="isSeries"
            />
            <div v-else class="loading-container">
              <Empty />
            </div>
          </div>
          <slot name="modal-body" />
        </Card>
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import CollapsePanel from './CollapsePanel.vue';
import { Card, iSwitch, Empty } from '@/components';
import { equals, includes, propOr } from 'ramda';
import { getActiveCDPsService } from '../../../services/CPDService';

export default {
  components: {
    Card,
    CollapsePanel,
    Empty,
    iSwitch
  },
  data() {
    return {
      allowAutoZoom: false,
      data: null,
      date: null,
      dateTime: null,
      lastEmitedData: [],
      layersActive: [],
      CDPs: [],
      oIsOpenPanel: this.isOpenPanel,
      series: null,
      showCdpColors: false,
      showUnassigned: false,
      updateInterval: 5 * 60,
      checkedRush: false,
      dataObject: {
        unassigned: 0,
        inboxDeliveries: 0
      },
      loadingSwitch: {
        assigned: false,
        successful: false,
        unassigned: false,
        unsuccessful: false,
        unassignedRush: false
      },
      loading: {
        switch: false,
        update: true,
        spiner: false
      },
      currentResult: null,
      chartOptions: {
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val, opts) => {
            return this.series[opts.seriesIndex];
          }
        },
        labels: [
          'Successful',
          'In progress',
          'Canceled',
          'Rejected',
          'Failed',
          'unassigned canceled'
        ],
        colors: ['#56B260', '#7685F9', '#BE3F4E', '#EB5B6C', '#FA97A2', '#FCC5CB'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        noData: {
          text: Card,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        }
      },
      requestsLayersDateLog: {
        common: '',
        unassigned: ''
      },
      cancelTokens: {}
    };
  },

  created() {
    this.$store.subscribe(({ type, payload }) => {
      if (type === 'map/update') {
        const { action } = payload;
        if (action === 'dashboardUpdate') {
          clearInterval(this.getDataTimeout);
          this.getData();
        }
      }
    });
  },
  mounted() {
    this.date = this.$moment(new Date()).format('Y-MM-DD');
    this.dateTime = this.$moment(new Date()).format('Y-MM-DD 00:00:00');
    this.getCDPs();
  },
  computed: {
    hasActiveLayers() {
      return this.layersActive.length > 0;
    },
    totalUnsuccessfulDeliveries() {
      const details = this.data.total_unsuccessful_deliveries_details;

      return details.canceled + details.rejected + details.failed + details.unattended_canceled;
    },
    isSeries() {
      return this.series.find((serie) => {
        return serie !== 0;
      });
    },
    disabledRushUnassigned() {
      return (
        !this.showUnassigned ||
        this.loadingSwitch.unassigned ||
        this.data.total_unassigned_rush <= 0
      );
    },
    CDPsColors() {
      return this.CDPs.map(({ id, color }) => ({ id, color }));
    },
    CDPsMarkers() {
      const CDPsMarkers = [];
      if (this.showCdpColors)
        this.CDPs.map((cdp) => {
          CDPsMarkers.push({
            ...cdp,
            typeMarker: 'cdp',
            icon: 'medical-bag',
            id: `cpd-${cdp.id}`,
            position: { lat: cdp.latitude, lng: cdp.longitude }
          });
        });
      return CDPsMarkers;
    }
  },
  methods: {
    handleSwitchChange(value, type, loading, executeRequest) {
      this.allowAutoZoom = true;
      this.getDashboardTotals(value, type, loading, executeRequest);
    },
    async getData() {
      this.loading.spiner = true;
      this.currentResult = null;
      this.allowAutoZoom = false;

      try {
        const results = await Promise.all([
          this.Api.get(`/system/statistics/daily_dispatching?date=${this.date}`),
          this.getDeliveriesInbox(),
          this.getUnattended()
        ]);
        const data = results[0]?.data || [];
        this.dataObject.inboxDeliveries = results[1];
        this.dataObject.unassigned = results[2];
        this.data = data;
        this.series = [
          data.total_successful_deliveries,
          data.total_in_progress_deliveries,
          data.total_unsuccessful_deliveries_details.canceled,
          data.total_unsuccessful_deliveries_details.rejected,
          data.total_unsuccessful_deliveries_details.failed,
          data.total_unsuccessful_deliveries_details.unattended_canceled
        ];

        if (this.hasActiveLayers) {
          const promises = this.layersActive.map((type) => {
            return this.getDashboardTotals(1, type, 1);
          });
          await Promise.all(promises);
        }
      } catch (error) {
        console.log(error);
      }
      this.loading.spiner = false;
      this.loading.update = false;
      this.setNextUpdate();
    },
    async getCDPs() {
      const result = await getActiveCDPsService();
      this.CDPs = result || [];
    },
    async getUnattended() {
      try {
        const { data } = await this.Api.get(
          `/delivery_manifest/unattended_deliveries/total_items?delivery_date=${this.$moment(
            new Date()
          ).format('Y-MM-DD 00:00:00')}&canceled=0`,
          {
            cancelToken: this.generateCancelToken('UNATTENDED')
          }
        );
        return data[0].total_items;
      } catch (error) {
        console.log(error);
      }
    },
    async getDeliveriesInbox() {
      try {
        const { data } = await this.Api.get(
          `/deliveries_inbox/total_items?delivery_date=${this.$moment(new Date()).format(
            'MM/DD/YYYY'
          )}`,
          {
            cancelToken: this.generateCancelToken('DELIVERY_INBOX')
          }
        );
        return data.total_items;
      } catch (error) {
        console.log(error);
      }
    },
    setNextUpdate() {
      clearTimeout(this.getDataTimeout);
      this.getDataTimeout = setTimeout(() => {
        this.getData();
      }, this.updateInterval * 1000);
    },
    validateTimeRestrictions(data = []) {
      if (!data) return [];

      return data.map((delivery) => {
        const { delivery_start_time, delivery_end_time } = delivery;
        const hasDeliveryTimeRestriction = Boolean(delivery_start_time || delivery_end_time);
        const deliveryTimeRestrictionColor = hasDeliveryTimeRestriction ? '#6741A5' : null;
        return { ...delivery, deliveryTimeRestrictionColor };
      });
    },
    async getDashboardTotals(value, type, loading, executeRequest = true) {
      this.cancelRequest(type);
      const isUnassigned = type === 'unassigned';
      let result = [];
      let url = '';
      if (loading) {
        if (!this.checkedRush) this.loadingSwitch[type] = true;
        else this.loadingSwitch[isUnassigned ? 'unassignedRush' : type] = true;
      }
      if (value) {
        if (!includes(type, this.layersActive)) this.layersActive.push(type);
        let data = [];
        const now = this.$moment();
        const lastRequestDate = this.requestsLayersDateLog[isUnassigned ? 'unassigned' : 'common'];
        const isLastRequestToSoon = now.diff(lastRequestDate, 'seconds') < 60;

        if (executeRequest) {
          let requestConfig = {
            apiName: 'all/delivery_events',
            canceledQuery: ''
          };
          if (isUnassigned) {
            requestConfig = {
              apiName: 'unattended_deliveries',
              canceledQuery: '&canceled=0'
            };
          }
          if (!loading && isLastRequestToSoon) {
            data = this.lastEmitedData;
          } else {
            url = `/delivery_manifest/${requestConfig.apiName}?page=1&per_page=99999&delivery_date=${this.dateTime}${requestConfig.canceledQuery}`;

            this.requestsLayersDateLog[isUnassigned ? 'unassigned' : 'common'] = now.toDate();
            const result = await this.Api.get(url, { cancelToken: this.generateCancelToken(type) });
            data = this.validateTimeRestrictions(result.data);
          }

          if (type === 'unsuccessful') {
            const unsuccess = await this.getUnsuccessfulData();
            data = [...data, ...unsuccess];
          }
        }
        this.addInformationMarker(data, type);

        if (type === 'successful') {
          result = data.filter((marker) => marker.delivery_event_status_id === 4);
        } else if (type === 'unsuccessful') {
          result = data.filter(
            (marker) =>
              [11, 7, 6].includes(marker.delivery_event_status_id) || marker.canceled === 1
          );
        } else result = data;
        if (this.currentResult) result = [...this.currentResult, ...result];

        result = result.filter(
          (firtsmarker, index, array) =>
            array.findIndex((marker) =>
              ['id', 'typeMarker'].every((key) => marker[key] === firtsmarker[key])
            ) === index
        );
        this.currentResult = result;
      } else if (this.currentResult) {
        result = this.currentResult.filter((marker) => marker.typeMarker != type);
        this.currentResult = result;
      } else {
        this.layersActive = this.layersActive.filter((marker) => marker !== type);
      }
      if (result) {
        result = result.filter(
          (firtsmarker, index, array) =>
            array.findIndex((marker) => ['id'].every((key) => marker[key] === firtsmarker[key])) ===
            index
        );
      }
      this.emitResult(result);

      if (loading) {
        if (!this.checkedRush) this.loadingSwitch[type] = false;
        else this.loadingSwitch[isUnassigned ? 'unassignedRush' : type] = false;
      }
    },
    generateCancelToken(item) {
      this.cancelTokens[item] = this.Api.cancelToken;
      return this.cancelTokens[item].token;
    },
    async getUnsuccessfulData() {
      try {
        const url = `/delivery_manifest/unattended_deliveries?page=1&per_page=99999&delivery_date=${this.dateTime}&canceled=1`;
        const { data } = await this.Api.get(url, {
          cancelToken: this.generateCancelToken('UNSUCCESSFUL')
        });

        return this.validateTimeRestrictions(data);
      } catch (error) {
        console.log(error);
      }
    },
    addInformationMarker(markers, type) {
      markers.map((marker) => {
        let markers = {
          successful: (marker) => {
            marker.icon = 'check-circle';
            marker.color = '1D8227';
            return marker;
          },
          unsuccessful: () => {
            marker.icon = 'cancel';
            marker.color = 'BE3F4E';
            return marker;
          },
          assigned: () => {
            marker.icon = 'clipboard-account';
            marker.color = '3D4FD2';
            return marker;
          },
          unassigned: () => {
            marker.icon = 'clipboard-remove';
            marker.color = 'CC8301';
            marker.cdpColor = null;
            return marker;
          },
          rush: () => {
            marker.icon = 'alpha-r-circle';
            marker.color = '968A32';
            return marker;
          }
        };
        const isRush = type === 'unassigned' && marker.rush === 1;
        markers[isRush ? 'rush' : type](marker);

        marker.typeMarker = type;
        const lat = marker.participant_latitude || marker.destination_latitude;
        const lng = marker.participant_longitude || marker.destination_longitude;
        marker.position = { lat, lng };
      });
    },
    emitResult(data, forced = false) {
      if (this.checkedRush) data = data.filter((marker) => !marker?.is_attended && marker.rush);

      if (!forced) {
        let isSameData = equals(data, this.lastEmitedData);
        if (data.length && this.lastEmitedData) {
          const equalSizes = data.length === this.lastEmitedData.length;
          isSameData = equalSizes && data.every((R, i) => equals(R, this.lastEmitedData[i]));
        }
        if (isSameData) return;
      }
      const result = this.validateShowUnassignedCdpColors(data);
      this.$emit('showOnMap', result, this.allowAutoZoom);
      this.allowAutoZoom = false;
      this.lastEmitedData = data;
    },
    filteredCDPsMarkers(deliveries) {
      return this.CDPsMarkers.filter((cdp) => {
        const cdpId = cdp.id.replace('cpd-', '');
        return deliveries.some((delivery) => delivery.close_door_pharmacy_id == cdpId);
      });
    },
    validateShowUnassignedCdpColors(deliveries) {
      deliveries.map((delivery) => {
        if (delivery.typeMarker !== 'unassigned') return;

        const cdpId = delivery.close_door_pharmacy_id;

        if (this.showCdpColors) {
          delivery.originalColor = delivery.color;
          const oCdpColor = this.CDPsColors.find((cdpColor) => cdpColor.id === cdpId);
          const cdpColor = propOr(null, 'color', oCdpColor);
          if (cdpColor) delivery.color = cdpColor;
        } else {
          const oColor = delivery?.originalColor;
          if (oColor && oColor !== delivery.color) delivery.color = oColor;
        }
      });
      return [...deliveries, ...this.filteredCDPsMarkers(deliveries)];
    },
    cancelRequest(item) {
      if (item) this.cancelTokens[item]?.cancel();
      else
        Object.values(this.cancelTokens).map((cancelToken) => {
          if (cancelToken) cancelToken.cancel();
        });
    }
  },
  watch: {
    showCdpColors() {
      this.emitResult(this.lastEmitedData, true);
    },
    oIsOpenPanel(value) {
      if (value) this.getData();
      else if (!this.hasActiveLayers) clearInterval(this.getDataTimeout);

      this.$emit('update:isOpenPanel', value);
    }
  },
  props: {
    isOpenPanel: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#dashboard
  border-bottom-right-radius: $br-md
  border-bottom-left-radius: $br-md
  overflow: hidden
  left: 450px
  #chartDashboard
    ::v-deep
      .apexcharts-pie-area
        stroke: transparent !important
  .header-layers
    display: flex
    font-weight: 700
    min-height: 28px !important
    span
      margin-right: 8px
    .loading-spin
      margin-left: auto
  .card-dashboard
    h5
      text-align: center
  .deliveries
    overflow: auto
    margin: 0 -10px
    .loading-container
      height: 200px
    .infinite-scroll
      padding-top: 2px
  .inbox-text
    margin-left: 47.2px
    padding-left: 11px
  .filters
    color: $primary
    margin-bottom: 15px
    label
      font-weight: bold
      margin-right: 15px
  ::v-deep
    .collapse
      .collapse-content
        .card-content
          min-height: auto
  .tag-container
    display: flex
    justify-content: space-between
    ::v-deep
      .b-checkbox
        margin-left: 55px
  .tag
    min-width: 90px
    font-size: 14px
    color: $dark-600
    border: 1px solid $gray-100
    background: $gray-100
    margin-bottom: 0.2rem
    border-radius: 2rem
    &.clickable
      cursor: pointer
    ::v-deep
      & + *
        margin-left: 3px
  @each $i,$color in $deliveryStatusColors
    .#{$i}
      border-color: $color
      background: $color
  @each $i,$color in $deliveryStatusColorsDark
    .map-#{$i}
      .MapMarker
        ::v-deep
          .mm-border
            fill: darken($color,15)
          .mm-regular
            fill: $color
          .mm-darken
            fill: darken($color,7)
  .text-white
    color: $white !important
</style>

<style lang="sass">
.dark
  #dashboard
    .card-dashboard
      background-color: $dark-500
      border: 1px $gray-700 solid
    .card-dashboard h5, .header-layers, .tag-container, .tag
      color: $gray-200
    #chartDashboard
      .apexcharts-legend-text
        color: $gray-200 !important
    .tag
      color: $main-background!important
      &.scheduled, &.picked-up,&.pick-up, &.return, &.start-lunch-break,&.end-lunch-break
        color: $dark!important
      @each $i,$color in $deliveryStatusColorsDark
        &.#{$i}
          border-color: $color!important
          background: $color!important
    .inbox
      color: $dark !important
      background-color: $gray-500 !important
      border: none
</style>
