import { map_apiKey } from '@/config/constants.js';
import { toast } from '@/utils/dialog';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import GPS from '@/components/Map/GPS/GPS.vue';
import Layers from '@/components/Map/Layers.vue';
import MapHeader from './Header.vue';
import MapAddresPicker from '../MapAddresPicker.vue';
import { darkMapStyle, lightMapStyle } from '@/utils/MapStyles';
import Menu from '@/components/Map/Menu.vue';
import { PhoneViewer, IconMarker, Link } from '@/components';
import Vue from 'vue';

Vue.use(VueGoogleMaps, { load: { key: map_apiKey }, libraries: 'geocoder' });
export default {
  components: {
    GmapCustomMarker,
    GPS,
    IconMarker,
    Layers,
    Link,
    MapAddresPicker,
    MapHeader,
    Menu,
    PhoneViewer
  },
  async mounted() {
    this.map = await this.$refs.mapRef.$mapPromise;
    this.geocoder = new window.google.maps.Geocoder();
  },
  data() {
    return {
      mapAddressPickerData: null,
      mapAddressPickerHelper: null,
      action: '',
      activeLayers: [],
      addressMarker: { position: { lat: null, lng: null } },
      backup: null,
      geocoder: null,
      isHiddenMenu: false,
      GPS: {
        isOpen: false,
        tablet: { focus: null, data: [] }
      },
      infowindows: { destination: {}, zipcode: {} },
      map: null,
      mapStyle: { styles: this.darkMode ? darkMapStyle : lightMapStyle },
      oMap: { center: { lat: 36.610423, lng: -119.928424 }, zoom: 8 },
      search: { query: '', delayer: null, loading: false },
      // unsubscribe: null,
      view: this.$route.query.view
    };
  },
  computed: {
    zipcodes() {
      this.infowindows.zipcode = {};
      return this.activeLayers.filter(({ name }) => name == 'Zipcodes')[0] || {};
    },
    destinations() {
      this.infowindows.destination = {};
      return this.activeLayers.filter(({ name }) => name == 'Destinations')[0] || {};
    }
  },
  methods: {
    // aliveMapSetup() {
    //   window.addEventListener('beforeunload', this.aliveMap(false));
    //   this.aliveMap(true);
    // },
    // aliveMap(status) {
    //   this.$store.dispatch('map/isAlive', status);
    //   setInterval(() => {
    //     this.$store.dispatch('map/isAlive', status);
    //   }, 30000);
    // },
    activeLayersPaths() {
      let layersPath = [];
      this.activeLayers.map((layer) => (layersPath = [...layersPath, ...layer.mPath]));
      const addressMarker = this.addressMarker?.position || null;
      let path = [
        ...layersPath,
        ...this.GPS.tablet.data
          .filter((d) => d.onMap)
          .map((d) => ({ lat: d.latitude, lng: d.longitude }))
      ];
      addressMarker.lat ? path.push(addressMarker) : null;
      return path;
    },
    action_backup() {
      console.log('::backup', this.backup);
      if (!this.backup)
        this.backup = {
          isHiddenMenu: this.isHiddenMenu,
          oMap: { ...this.oMap },
          addressMarker: { ...this.addressMarker }
        };
      console.log('::backup', this.backup);
    },
    async action_getAddress({ latLng, draggable = true, zoom = 17 }) {
      console.log('action_getAddress', latLng);
      this.oMap = { ...this.oMap, center: latLng, zoom };
      this.addressMarker = { position: latLng, draggable };
      const address = await this.mapAddressFromPosition(latLng);
      this.response('position', { address });
    },
    async action_getPosition({ address, city, state, zipcode, full_address, latitude, longitude }) {
      this.isHiddenMenu = true;
      this.mapAddressPickerData = {
        address: {
          number: null,
          street: address,
          city,
          state,
          zipcode,
          fullAddress: full_address
        },
        isLoading: false,
        isValid: true,
        position: latitude && longitude ? { lat: latitude, lng: longitude } : null
      };
    },
    action_showTablet({ id }) {
      console.log('action_showTablet', id);
    },
    async action_showPosition({ address, latLng, draggable, zoom = 17 }) {
      this.mapAddressPickerData = null;
      this.action_restore(false);
      await this.delay(100);
      let hasLatLng = latLng.lat && latLng.lng;
      if (address && !hasLatLng) {
        latLng = await this.mapPositionFromAddress(address);
        hasLatLng = latLng.lat && latLng.lng;
      }
      if (hasLatLng) {
        const center = latLng || this.oMap.center;
        // this.oMap = { ...this.oMap, center, zoom };
        zoom;
        this.oMap = { ...this.oMap };
        this.addressMarker = { position: center, draggable };
        this.autoZoom();
      } else {
        toast('error', `${this.$t('errors.GaddressNotFound')}:<br/>${address}`, 10000);
      }
    },
    action_restore(deleteBackup = true) {
      const { oMap, addressMarker, isHiddenMenu } = this.backup;
      this.mapAddressPickerData = null;
      this.oMap = oMap;
      this.addressMarker = addressMarker;
      if (deleteBackup) {
        this.$store.commit('map/breadcumbs', []);
        this.backup = null;
      }
      this.isHiddenMenu = isHiddenMenu;
    },
    autoZoom(path = null) {
      path = path ? path : this.activeLayersPaths() || [];
      // console.log('autoZoom', path);
      if (path.length) {
        const { map } = this;
        if (map && path.length) {
          let latlngbounds = new window.google.maps.LatLngBounds();
          path.map((path) => (latlngbounds = this.maplatLngExtendPath(latlngbounds, path)));
          map.fitBounds(latlngbounds);
          if (map.getZoom() > 18) map.setZoom(18);
        }
      } else {
        // this.oMap.zoom = 12;
      }
    },
    checkGPSInfowindow(field, GPSs) {
      const gps = GPSs.filter((g) => g.onMap && g.imei === this.infowindows[field].imei)[0];
      if (gps) this.infowindows[field] = gps;
      else this.infowindows[field] = {};
    },
    checkUpdate({ action, data }) {
      if (action !== 'restore') {
        this.action_backup();
      }
      try {
        this.action = action;
        this[`action_${action}`](data);
      } catch (error) {
        console.error(error);
      }
    },
    delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    mapClick() {},
    async mapAddressFromPosition(location) {
      console.log('mapAddressFromPosition', location);
      this.geocoder.geocode({ location }, (results, status) => {
        // TODO Complete this.
        console.log(results, status);
        if (status == 'OK') {
          console.log(results);
        }
      });
    },
    mapIdle() {
      this.oMap.zoom = this.map.getZoom();
      this.oMap.center = this.map.getCenter();
    },
    async mapPositionFromAddress(address) {
      console.log('mapPositionFromAddress', address);
      return new Promise((response) => {
        let position = null;
        this.geocoder.geocode({ address }, (results, status) => {
          console.log('Results', results);
          const result = (results && results[0]) || {};
          // if (status == 'OK' && result.types.find((t) => t == 'street_address')) {
          if (status == 'OK') {
            const { location } = result.geometry || {};
            position = { lat: location.lat(), lng: location.lng() };
            this.addressMarker.position = position;
            this.oMap.center = position;
          } else {
            console.error('FAIL');
          }
          response(position);
        });
      });
    },
    maplatLngExtendPath(latlngbounds, paths) {
      if (paths.lat) {
        return latlngbounds.extend(paths);
      } else {
        paths.map((path) => {
          latlngbounds = this.maplatLngExtendPath(latlngbounds, path);
        });
        return latlngbounds;
      }
    },
    onDispatchMode() {
      this.$store.dispatch('map/updateMapWindow', {
        action: 'showDispatch',
        view: 'dispatch',
        data: []
      });
    },
    onLayersChange(layers) {
      layers.map((layer) => {
        let oPath = [];
        layer.data.map(({ center_latitude, center_longitude, latitude, longitude, path }) => {
          const lat = center_latitude || latitude;
          const lng = center_longitude || longitude;
          if (lat && lng) oPath.push([{ lat, lng }]);
          else if (path) oPath = path;
        });
        layer.mPath = oPath;
      });
      this.activeLayers = layers;
      this.autoZoom();
    },
    onGPSsChange(field, { isAutoUpdate, GPSs, GPS }) {
      this.GPS[field].data = GPSs;
      this.checkGPSInfowindow(field, GPSs);
      if (!isAutoUpdate) {
        if (GPS) this.autoZoom([{ lat: GPS.latitude, lng: GPS.longitude }]);
        else this.autoZoom();
      }
    },
    onAddressMarkerClick(onAddressMarkerClick) {
      console.log({ onAddressMarkerClick });
    },
    // async onAddressMarkerDrag(e) {
    // ?? delete
    //   const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    //   const response = { latLng };
    //   if (this.action == 'getAddress') response.address = await this.mapAddressFromPosition(latLng);
    //   this.response('position', response);
    // },
    setMapAddresPickerPosition({ position, isHelper }) {
      if (isHelper) {
        this.mapAddressPickerHelper = { position };
      } else {
        this.mapAddressPickerData = { position };
      }
    },
    onSearch(query) {
      this.search.delay;
      clearInterval(this.search.delayer);
      this.search.delayer = setTimeout(() => {
        this.search.loading = true;
        this.search.query = query;
        this.search.loading = false;
      }, 500);
    },
    response(action, data) {
      this.$emit('response', { action, data });
    }
  },
  watch: {
    mapAddressPickerData(value) {
      this.mapAddressPickerHelper = value;
    },
    darkMode(val) {
      this.mapStyle = { styles: val ? darkMapStyle : lightMapStyle };
    },
    update(data) {
      this.checkUpdate(data);
    }
  },
  props: {
    darkMode: { type: Boolean, default: false },
    update: { type: Object, default: () => {} }
  }
};
