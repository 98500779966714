<template>
  <Card class="driver-status-card" @click="onSelect" :hasMinHeight="false">
    <div class="picture-container">
      <div class="picture" :style="`border-color: ${driver.color}`">
        <Picture
          :src="driver.urlImage"
          :preasigned="{
            apiUrl: '/presigned/employees',
            fileName: `${driver.id}.png`
          }"
          alt="Driver fullname"
        />
      </div>
    </div>
    <div class="driver-content flex f-column f-jc">
      <div class="header">
        <strong id="driver-name">{{ driver.employee_name || '{employee_name}' }} </strong>
      </div>
      <div class="driver-body">
        <!-- <strong> -- {{ $t('global.efficiency') }}</strong> <br /> -->
        <FormatText capitalize v-if="driver.total_deliveries">
          {{ $t('global.countDeliveries', [driver.complete_deliveries, driver.total_deliveries]) }}
        </FormatText>
      </div>
    </div>
    <div class="flex f-column f-jsb status-content f-aie">
      <div class="is-align-self-flex-end" @click="onStatusTagClick">
        <b-tag :class="`status-${status.strId}`" rounded>
          {{ status.name }}
        </b-tag>
      </div>
      <div class="info-content flex">
        <PhoneViewer v-if="driver.phone" justIcon @click.stop="() => {}" />
        <Link
          v-if="driver.position"
          :label="$t('button.showMap')"
          class="mr-2"
          icon="map-marker-radius"
          @click.stop="onShowOnMap"
        />
        <Link
          v-if="driver.delivery_manifest_id && driver.employee_status_id !== 1"
          @click.stop="addOn"
          class="ml-1 addon-button"
          :permission="Permission.addOn"
          underlined
        >
          {{ $t('dispatch.addOn') }}
        </Link>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, FormatText, Link, Picture, PhoneViewer } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Card,
    FormatText,
    Picture,
    PhoneViewer,
    Link
  },
  computed: {
    status() {
      const id = this.driver.employee_status_id;
      const status = {
        1: { strId: 'not-available', name: this.$t('drivers.notAvailable') },
        2: { strId: 'available', name: this.$t('drivers.available') },
        3: { strId: 'on-lunch-break', name: this.$t('drivers.onLunchBreak') },
        4: { strId: 'delivering', name: this.$t('drivers.inProgress') }
      };
      const deff = { name: `{driver.status} - ${id}` };
      return status[id] || deff;
    },
    Permission() {
      return Permissions.Dispatch;
    }
  },
  data() {
    return {};
  },
  methods: {
    addOn() {
      this.$emit('addOn', this.driver);
    },
    callDriver() {
      console.log('Método callDriver');
    },
    onSelect() {
      this.$emit('select', this.driver);
    },
    onShowOnMap() {
      this.$emit('showOnMap', this.driver);
    },
    onStatusTagClick() {
      this.$store.commit('map/driverStatusTagClick', this.driver?.employee_status_id);
    }
  },
  props: {
    driver: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.card
  padding: 0px
.driver-status-card
  ::v-deep
    .card-content
      padding: 16px
      display: flex
      &:hover
        .link,
        .tooltip-link
          visibility: visible
      .picture
        .letters
          border: none!important
.picture-container
  justify-content: center
  display: flex
  flex-flow: column
  margin-right: 12px
  .picture
    height: 48px
    max-width: 48px
    border-radius: $br-lg
    border: 4px solid
.driver-content
  width: 100%
  .header
    span
      font-size: 12px
      line-height: 130%
    strong
      font-size: $f-lg
  .driver-body
    strong
      font-size: 12px
      color: $gray-400
.status-content
  width: fit-content
  .info-content
    justify-content: space-between
    width: fit-content
    .addon-button
      font-weight: 500
.tag
  font-size: 14px
  padding: 8px 16px
  &.status-not-available
    background-color: $red-200
  &.status-on-lunch-break
    background-color: $gray-100
  &.status-delivering
    cursor: pointer
    background-color: $blue-100
  &.status-available
    background-color: $green-300
.link,
.tooltip-link
  visibility: hidden
</style>
<style lang="sass">
.dark
  .driver-status-card
    background: $gray-800!important
    &:hover
      box-shadow: 0 0 10px 0 $gray-600!important
    strong
      color: $gray-200
    .driver-body
      color: $main-background
    .info-content
      a
        color: $blue-300
        &:hover
          color: $blue-400
    .tag
      color: $main-background
      &.status-not-available
        background-color: $red-800
      &.status-on-lunch-break
        background-color: $gray-500
        color: $dark
      &.status-delivering
        background-color: $blue-600
      &.status-available
        background-color: $green-700
</style>
