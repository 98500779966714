<template>
  <CollapseCard
    icon="layers"
    :title="$t('map.layers.label')"
    :open="open"
    @toggle="(open) => $emit('update:open', open)"
  >
    <div v-if="loading">
      <b-skeleton height="25" width="100%" />
      <b-skeleton height="25" width="100%" />
    </div>
    <div v-else>
      <div class="search-fields">
        <Autocomplete
          :api="{
            url: `/catalogs/counties`,
            full: true
          }"
          :value="filter.county_area"
          :label="`${$t('global.searchBy')}: ${$t('map.layers.Counties')}`"
          @select="
            (data) => {
              filter.county_area = data;
            }
          "
          field="county_area"
          model="county_area"
          storeResults
          required
        />
        <Autocomplete
          :api="{
            url: `/destinations/categories`,
            full: true
          }"
          v-model="filter.category"
          :label="`${$t('global.searchBy')}: ${$t('map.layers.Counties')}`"
          model="category"
          field="category"
          clearable
          storeResults
        />
        <!-- <Map-layers :filter="filter" @activeLayers="(data) => $emit('activeLayers', data)" /> -->
      </div>
      <!-- {{ filter }} -->
      <div v-for="layer in layers" :key="layer.id" class="layer">
        <My-switch
          :value="layer.active"
          :text="$t(`map.layers.${layer.name}`)"
          @input="(active) => onLayerChange({ layer, active })"
          :loading="layer.loading"
          :disabled="!filter.county_area"
        />
        <div v-for="cat in layer.data_categories" :key="cat.query_param"></div>
      </div>
    </div>
  </CollapseCard>
</template>

<script>
import { coordinatesToPath } from '@/utils/MapFunctions.js';
import { layersEndPoint } from '@/config/constants.js';
import Autocomplete from '@/components/Autocomplete.vue';
import CollapseCard from './CollapseCard.vue';
import MySwitch from '@/components/iSwitch.vue';
// cfsnemt-testing.hmhsistemas.com.mx/common_assets/kmls/zip99929.kml
export default {
  components: {
    Autocomplete,
    CollapseCard,
    MySwitch
  },
  mounted() {
    this.getLayers();
  },
  data() {
    return {
      filter: { state_code: 'CA', county_area: 'SAN DIEGO COUNTY', category: '' },
      layers: [],
      loading: false
    };
  },
  computed: {
    activeLayers() {
      return this.layers.filter((layer) => layer.active);
    }
  },
  methods: {
    async getLayerData(layer) {
      layer.loading = true;
      try {
        let params = ['per_page=9999'];
        Object.keys(this.filter).map((a) => {
          if (layer.data_categories.indexOf(a) >= 0) {
            const value = this.filter[a];
            if (value) {
              params.push(`${a}=${value}`);
            }
          }
        });
        const strParams = params.join('&');
        const { data } = await this.Api.get(`${layer.data_url}?${strParams}`);
        layer.data = data;
        data.map((item) => {
          if (item.geojson)
            item.path = coordinatesToPath(JSON.parse(item.geojson || '{}').coordinates);
        });
        return data || [];
      } catch (error) {
        console.error(error);
      }
      layer.loading = false;
    },
    generateKML_URI(layer) {
      // const urlPrefix = { Zipcodes: 'common_assets/kmls/zip' };
      // layer.data.map((d) => {});
      switch (layer.name) {
        case 'Zipcodes':
          layer.data.map((d) => {
            d.kmlUri = `${layersEndPoint}common_assets/kmls/zip${d.zipcode}.kml`;
          });
          break;

        default:
          break;
      }
    },
    async getLayers() {
      this.loading = true;
      try {
        const layers = [];
        const { data } = await this.Api.get('catalogs/layers');
        data.map((layer) => {
          layers.push({ ...layer, active: 0, data: [], loading: false, filter: {} });
        });
        this.layers = layers;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async onLayerChange({ layer, active }) {
      layer.active = active;
      layer.loading = true;
      if (!layer.data.length) {
        await this.getLayerData(layer);
        // this.generateKML_URI(layer);
      }
      layer.loading = false;
      layer.active = active;
      this.$emit('activeLayers', this.activeLayers);
    },
    updateActiveLayers() {
      const promises = [];
      this.activeLayers.map((layer) => {
        promises.push(this.getLayerData(layer));
      });
      Promise.all(promises).then(() => {
        this.$emit('activeLayers', this.activeLayers);
      });
    }
  },
  watch: {
    filter: {
      handler() {
        this.updateActiveLayers();
      },
      deep: true
    }
  },
  props: {
    open: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
.search-fields
  margin-bottom: 20px
  overflow: visible
</style>
