var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollapseCard',{attrs:{"icon":"layers","title":_vm.$t('map.layers.label'),"open":_vm.open},on:{"toggle":function (open) { return _vm.$emit('update:open', open); }}},[(_vm.loading)?_c('div',[_c('b-skeleton',{attrs:{"height":"25","width":"100%"}}),_c('b-skeleton',{attrs:{"height":"25","width":"100%"}})],1):_c('div',[_c('div',{staticClass:"search-fields"},[_c('Autocomplete',{attrs:{"api":{
          url: "/catalogs/counties",
          full: true
        },"value":_vm.filter.county_area,"label":((_vm.$t('global.searchBy')) + ": " + (_vm.$t('map.layers.Counties'))),"field":"county_area","model":"county_area","storeResults":"","required":""},on:{"select":function (data) {
            _vm.filter.county_area = data;
          }}}),_c('Autocomplete',{attrs:{"api":{
          url: "/destinations/categories",
          full: true
        },"label":((_vm.$t('global.searchBy')) + ": " + (_vm.$t('map.layers.Counties'))),"model":"category","field":"category","clearable":"","storeResults":""},model:{value:(_vm.filter.category),callback:function ($$v) {_vm.$set(_vm.filter, "category", $$v)},expression:"filter.category"}})],1),_vm._l((_vm.layers),function(layer){return _c('div',{key:layer.id,staticClass:"layer"},[_c('My-switch',{attrs:{"value":layer.active,"text":_vm.$t(("map.layers." + (layer.name))),"loading":layer.loading,"disabled":!_vm.filter.county_area},on:{"input":function (active) { return _vm.onLayerChange({ layer: layer, active: active }); }}}),_vm._l((layer.data_categories),function(cat){return _c('div',{key:cat.query_param})})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }