<template>
  <div class="header map-header">
    <div class="breadcumbs">
      <template v-for="(b, i) in breadcumbs">
        <span class="capitalize" :key="i"> {{ $t(b) }}</span>
        <template v-if="i < breadcumbs.length - 1"> / </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  methods: {},
  computed: {
    breadcumbs() {
      const breadcumbs = this.$store.state.map?.breadcumbs || [];
      breadcumbs.push('map.label');
      return breadcumbs;
    }
  }
};
</script>

<style lang="sass" scoped>
.header
  width: 100%
  height: 80px
  display: flex
  padding: 0 40px
  margin: 0
  align-items: center
  justify-content: space-between
  background: white
  color: $dark
.breadcumbs
  font-weight: bold
  font-size: $f-xxl
</style>
