<template>
  <div class="deliveriesStatusByManifest">
    <div v-if="loading" class="p-3">
      <b-skeleton v-for="i in 8" :key="i" height="100" />
    </div>
    <slot v-else />
  </div>
</template>

<script>
import { generatePositionLatLng } from '@/utils/MapFunctions';
import { clone, equals, find, findIndex, propEq } from 'ramda';

export default {
  components: {},
  mounted() {
    this.getManifest();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  data() {
    return {
      isAutoUpdate: false,
      updateInterval: 15,
      timeout: 0,
      coded_route: null,
      events: [],
      loading: true,
      cancelToken: null,
      total_deliveries: null,
      total_deliveries_pickedup: null
    };
  },
  methods: {
    cancelRequest() {
      if (this.cancelToken) this.cancelToken.cancel();
    },
    clean() {
      this.events = [];
      this.coded_route = '';
      this.total_deliveries = '';
      this.total_deliveries_pickedup = '';
    },
    checkIfEventsHasChanged(events = []) {
      const hasDifferentLength = this.events?.length !== events.length;
      if (hasDifferentLength) return true;

      const oEvents = clone(this.events);
      return !oEvents.every((event, i) => {
        delete event.position;
        return equals(event, events[i]);
      });
    },
    async getManifest() {
      clearTimeout(this.timeout);
      this.cancelRequest();
      if (!this.manifestId) return;
      try {
        this.cancelToken = this.Api.cancelToken;
        if (!this.isAutoUpdate) {
          this.clean();
          this.loading = true;
        }
        const { data } = await this.Api.get(`delivery_manifest/${this.manifestId}`, {
          cancelToken: this.cancelToken.token
        });

        const { events = [], coded_route, total_deliveries_pickedup, total_deliveries } = data;
        const eventsHasChanged = this.checkIfEventsHasChanged(events);
        generatePositionLatLng(events, {
          lat: 'destination_latitude',
          lng: 'destination_longitude'
        });
        this.coded_route = coded_route;
        this.total_deliveries = total_deliveries;
        this.total_deliveries_pickedup = total_deliveries_pickedup;

        if (eventsHasChanged) {
          if (this.isAutoUpdate) {
            this.updateData(events);
          } else {
            this.events = events;
          }
        }
        this.loading = false;

        this.$nextTick(() => (this.isAutoUpdate = false));
      } catch (Err) {
        if (!Err.aborted) this.loading = false;
      }
      this.timeout = setTimeout(() => {
        this.isAutoUpdate = true;
        this.getManifest();
      }, this.updateInterval * 1000);
    },
    updateData(newEvents) {
      const { events } = this;
      newEvents.map((u) => {
        if (equals(find(propEq('id', u.id), events), u)) return;
        const index = findIndex(propEq('id', u.id), events);
        if (index != -1) this.$set(this.events, index, u);
        else this.events.unshift(u);
      });
    }
  },
  watch: {
    events(events) {
      this.$emit('update', {
        isAutoUpdate: this.isAutoUpdate,
        coded_route: this.coded_route,
        events,
        total_deliveries_pickedup: this.total_deliveries_pickedup,
        total_deliveries: this.total_deliveries
      });
    },
    manifestId() {
      this.getManifest();
    }
  },
  props: {
    manifestId: { type: Number, default: 0 },
    filters: { type: Array, default: () => [] }
  }
};
</script>

<style lang="sass" scoped>
.deliveriesStatusByManifest
  overflow: auto
  height: 100%
</style>
