<template>
  <NMarker
    v-if="data"
    :zIndex="10"
    :position="data.position"
    icon="clipboard-remove"
    iconColor="#CC8301"
    autoOpenInfowindow
  >
    <template #infowindow v-if="data">
      <div class="flex f-column">
        <table class="unassigned-info">
          <tr>
            <td class="titlel-table p-1">
              <strong> {{ $t('global.name') }}</strong>
            </td>
            <td class="p-1">{{ data.participant_full_name }}</td>
          </tr>
          <tr>
            <td class="titlel-table p-1">
              <strong> {{ $t('contact.address') }}</strong>
            </td>
            <td class="p-1">
              <strong> {{ data.participant_full_address }}</strong>
            </td>
          </tr>

          <tr>
            <td class="titlel-table p-1">
              <strong> {{ $t('global.orderNumber') }}</strong>
            </td>

            <td class="p-1">
              {{ data.order_number }}
            </td>
          </tr>
        </table>

        <Link class="remove" @click="$emit('removed')">{{ $t('global.removeMarker') }}</Link>
      </div>
    </template>
  </NMarker>
</template>

<script>
import { NMarker } from '@/components/Map/Plugins';
import { Link } from '@/components';

export default {
  components: {
    Link,
    NMarker
  },
  props: {
    data: { type: Object, default: null }
  }
};
</script>
<style lang="sass" scoped>
.unassigned-info
  max-width: 400px
  .titlel-table
    max-width: 75px
.remove
  align-self: flex-end
</style>
