<template>
  <div>
    <Deliveries :isOpen.sync="addOn.isOpen" :manifestId="manifestId" isAddOn isFromMap />
    <CollapsePanel
      :title="$t('drivers.status')"
      :search="search"
      @search="(q) => (search = q)"
      id="driverStatus"
      :fieldPlaceholder="''"
      queryKey="driverQuery"
      :isOpen.sync="oIsOpenPanel"
    >
      <template #filters>
        <div class="filters flex f-jsb">
          <div>
            <label>{{ $t('global.sortBy') }}</label>
            <Dropdown :maxHeight="200" v-model="currentSort" :items="sortItems" />
          </div>
          <div>
            <Link underlined @click="reset" :disabled="!isClearAllowed">{{
              $t('button.clear')
            }}</Link>
          </div>
        </div>
      </template>
      <div v-if="sortedDrivers.length || loading" class="driver-satuts-scrollarea">
        <b-loading v-if="loading" :is-full-page="false" active />
        <template v-else>
          <DriverStatusCard
            v-for="driver in sortedDrivers"
            :class="activeDriver.id === driver.id ? 'active' : ''"
            :key="driver.id"
            @addOn="onAddOn"
            @select="onDriverSelect"
            @showOnMap="onShowOnMap"
            :driver="driver"
          />
        </template>
      </div>
      <Empty v-else />
    </CollapsePanel>
  </div>
</template>

<script>
import { Deliveries } from '@/views/Fragments';
import CollapsePanel from './CollapsePanel.vue';
import DriverStatusCard from '@/components/Dispatch/Map/DriverStatusCard.vue';
import Dropdown from '@/components/Dropdown';
import Empty from '@/components/Empty';
import Link from '@/components/Link';
import { sortBy, compose, toLower, prop, equals } from 'ramda';
import colorsArray from '@/utils/ColorsArray';

export default {
  components: {
    Deliveries,
    CollapsePanel,
    DriverStatusCard,
    Empty,
    Dropdown,
    Link
  },
  created() {
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { map: { manifestId, timelineManifest } }) => {
        if (type === 'map/timelineManifest') {
          console.log('driver', timelineManifest);
        }
        if (type === 'map/manifestId') {
          this.activeDriver =
            this.drivers.filter(
              ({ delivery_manifest_id }) => delivery_manifest_id === manifestId
            )[0] || {};
        }
      }
    );
  },
  mounted() {
    this.init();
  },
  destroyed() {
    clearTimeout(this.getDataTimeout);
  },
  data() {
    return {
      activeDriver: {},
      driversPositions: [],
      addOn: { isOpen: false },
      currentSort: {},
      colorsArray,
      colorsIndex: 0,
      drivers: [],
      loading: false,
      getDataTimeout: null,
      lastUpdate: null,
      manifestId: null,
      search: '',
      updateInterval: 15,
      oIsOpenPanel: this.isOpenPanel
    };
  },
  computed: {
    colors() {
      return { all: this.colorsArray, maxIndex: this.colorsArray.length - 1 };
    },
    isClearAllowed() {
      return !!this.activeDriver.id || !!this.search;
    },
    sortItems() {
      return [
        { uuid: 'employee_name', value: this.$t('employee.name.driver') }
        // { uuid: '-', value: '-' }
      ];
    },
    sortedDrivers() {
      const { uuid } = this.currentSort;
      const sortByKey = sortBy(compose(toLower, prop(uuid)));
      switch (uuid) {
        case '-':
          return this.filteredDrivers;
        default:
          return sortByKey(this.filteredDrivers);
      }
    },
    filteredDrivers() {
      let { drivers } = this;
      drivers = drivers.filter((D) => D.id !== 18); // FILTER GOOGLE USER
      if (this.search)
        drivers = drivers.filter((D) => {
          let found = false;
          const strSearch = this.search.toLowerCase();
          Object.keys(D).map((k) => {
            const strValue = String(D[k]).toLowerCase();
            if (strValue.search(strSearch) != -1) found = true;
          });
          if (found) return D;
        });
      return drivers;
    }
  },
  methods: {
    init() {
      this.loading = true;
      this.setLastUpdate();
      this.getData();
    },
    getAColor() {
      const { colors, colorsIndex } = this;
      const color = colors.all[colorsIndex];
      if (colorsIndex === colors.maxIndex) this.colorsIndex = 0;
      else this.colorsIndex = colorsIndex + 1;
      return color;
    },
    async getManifestData() {
      try {
        const { data } = await this.Api.get(
          `/delivery_manifest/drivers_statuses?last_update=${this.lastUpdate}`
        );
        return data;
      } catch (error) {
        return [];
      }
    },
    async getData() {
      try {
        Promise.all([this.getDriversPositions(), this.getManifestData()]).then((result) => {
          this.driversPositions = result[0];
          this.setData(result[1]);
        });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
      this.setNextUpdate();
    },
    async getDriversPositions() {
      try {
        const { data } = await this.Api.get('/locations/last_positions');
        return data;
      } catch (err) {
        return [];
      }
    },
    onAddOn(driver) {
      this.manifestId = driver.delivery_manifest_id;
      this.addOn.isOpen = true;
    },
    onDriverSelect(driver = {}, reset = false) {
      if (this.activeDriver.id === driver.id || !driver.id) return;
      if (!driver.delivery_manifest_id && !reset) {
        driver = {};
        this.$toast('info', this.$t('drivers.messages.noManifest'), 1000);
      }
      this.$store.dispatch('map/manifestId', driver.delivery_manifest_id || 0);
      this.activeDriver = driver;
    },
    onShowOnMap(driver) {
      if (this.activeDriver.id !== driver.id) this.reset();
      this.$nextTick(() => {
        this.$emit('showOnMap', driver);
      });
    },
    reset() {
      this.$store.dispatch('map/manifestId', null);
      this.search = '';
      this.onDriverSelect();
    },
    getPositionDataByEmployee(driver) {
      return this.driversPositions.find((P) => P.employee_id == driver.id) || {};
    },
    async setData(updates = []) {
      updates.map((driver) => {
        this.setDriverColor(driver);
        this.setDriverUpdate(driver);
      });
      this.drivers.map((driver) => {
        this.setDriverPosition(driver);
      });
      this.$emit('update', this.drivers);
    },
    setDriverColor(driver) {
      driver.color = this.drivers.find((d) => d.id === driver.id)?.color || this.getAColor();
    },
    setDriverPosition(driver) {
      const { latitude, longitude, imei, speed } = this.getPositionDataByEmployee(driver);
      const lat = latitude || driver.latitude;
      const lng = longitude || driver.longitude;
      const tDriver = { ...driver, imei, speed };
      if (!equals(tDriver, driver)) {
        const position = lat && lng ? { lat, lng } : null;
        driver.imei = tDriver.imei;
        driver.speed = tDriver.speed;
        driver.position = position;
      }
    },
    setDriverUpdate(driver) {
      const index = this.drivers.findIndex((d) => d.id === driver.id);
      driver.position = null;
      if (index != -1) {
        this.$set(this.drivers, index, driver);
      } else {
        this.drivers.unshift(driver);
      }
    },
    setNextUpdate() {
      this.setLastUpdate();
      this.getDataTimeout = setTimeout(() => {
        this.getData();
      }, this.updateInterval * 1000);
    },
    setLastUpdate() {
      if (!this.lastUpdate) {
        this.lastUpdate = this.$moment().hours(0).format('Y-MM-DD HH:00:00.000');
      } else {
        this.lastUpdate = this.$moment().utc().format('Y-MM-DD HH:mm:ss.000');
      }
    }
  },
  watch: {
    oIsOpenPanel(value) {
      this.$emit('update:isOpenPanel', value);
    }
  },
  props: {
    isOpenPanel: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
#driverStatus
  right: 0
  border-bottom-left-radius: $br-md
  overflow: hidden
  .filters
    color: $primary
    margin-bottom: 15px
    label
      font-weight: bold
      margin-right: 15px
.active
  cursor: default!important
  box-shadow: 0 0 5px -1px $primary
.driver-satuts-scrollarea
  position: relative
  height: calc( 100vh - 150px )
  width: 100%
  overflow: auto
</style>

<style lang="sass">
.dark
  #driverStatus .filters
    color: $primary-dark
</style>
